import React, { Component, useState, useEffect, useCallback, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import bodybuilder from 'bodybuilder';

import {
    Form, Input, Icon, Button, Select, Row, Col, Card, Spin, InputNumber, 
    AutoComplete, Skeleton, Empty, Switch, message, BackTop, Radio, 
    Collapse, Tooltip, Tabs
} from 'antd';

import { withFirebase } from '../Firebase';
import { AuthUserContext, withEmailVerification, withAuthorization, withQuiz } from '../Session';
import { purposes, itemtypes, cues } from '../../constants/autocomplete';
import { useModal, useFormSubmit, useEsearch, useFirebase, useUpdateDb, useRemoveDb } from '../Hooks';

import NotesModal from './notes';
import Description, { ImgCarousel } from './description';
import Likes from './likes';
import styles from '../../index.css';



const { Option } = Select;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const TabPane = Tabs.TabPane;

const loadsize = 24;


// const itemTypeChildren = itemtypes.map(i => <Option key={i}>{i.charAt(0).toUpperCase() + i.slice(1)}</Option>);
const purposeChildren = purposes.map(i => <Option key={i}>{i}</Option>);
// const improvementsChildren = improvements.map(i => <Option key={i}>{i}</Option>);

// <span>
// Specify Some Criteria above, or <a href="# " onClick={props.populateLast}>Use the last set of Criteria</a> to Start Reviewing Items
// </span>



export const Resultcards = (props) => <React.Fragment>
{(props.hits && props.hits.length && props.hits.map(i =>
    <Col className="gutter-row" xs={20} sm={11} md={8} lg={6} key={i._source.pid}>
        {/* <Skeleton loading={props.loading} active> */}
            <Card style={{ marginTop: 40, width: "90%" }}
                bodyStyle={{ height: 0, display: "block", lineHeight: 0, overflow: "hidden", padding: 0 }}
                cover={<ImgCarousel source={i._source} />}
                // hoverable={true}
                actions={[<Icon type="heart" theme={props.like.includes(i._source.pid) ? "filled" : null} onClick={() => props.onClick('like', i._source)} />,
                <Icon type="dislike" theme={props.dislike.includes(i._source.pid) ? "filled" : null} onClick={() => props.onClick('dislike', i._source)} />,
                <Icon type="database" theme={props.own.includes(i._source.pid) ? "filled" : null} onClick={() => props.onClick('own', i._source)} />]}
            >
            {/* <span>{i._source.pid}</span> */}
            </Card>
        {/* </Skeleton> */}
    </Col>
)) || <div></div>}
{props.loading ? 
    <div style={{ paddingLeft: 24, paddingRight: 24, margin: "auto", maxWidth: 100, marginTop: 60 }}><Spin /></div>:
    (props.hits && props.hits.length? <div></div>: <Empty
        image="https://aworldoffantasies.files.wordpress.com/2012/07/narniawardrobe.jpg"
        description={props.description}
        />)}
</React.Fragment>




export const SearchResults = (props) => {



    const handleScroll = async (e) => {
        // const bottom = window.innerHeight + window.scrollY >= (e.target.body.scrollHeight - 150);
        const bottom = window.innerHeight + window.scrollY >= (document.body.offsetHeight - 50);


        if (props.hits && props.hits.length && bottom && !props.genloading && (props.offset===0 || props.offset%3!==0) && (props.offset+1)*loadsize < props.cue) { 
            // console.log("got to the bottom of search results");
            props.onPagination();

        };
           
    }


    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [props.hits, props.loading]);



    return (<div style={{ paddingLeft: 24, paddingRight: 24, maxWidth: 1200, margin: "auto"}} >

        <Row type="flex" justify="center" gutter={16} style={{ marginTop: 20 }}>
            <Resultcards {...props}/>
        </Row>
        {props.offset && props.offset%3===0 && (props.offset+1)*loadsize < props.cue? 
        <Row type="flex" justify="center" style={{ marginTop: 40, marginBottom: 40 }}>
            <Button type="primary" onClick={props.onLoadMore} style={{width: "33%"}}>Load More</Button>
            </Row>
            :<div style={{ marginTop: 40}}></div>}
    </div>);
}


export const FormDrawer = (props) => {


    return (

        <Collapse activeKey={props.visible ? ['1'] : []} bordered={false} onChange={() => props.setVisible(!props.visible)}>
            <Collapse.Panel header={<span style={{ fontSize: 16 }}>{props.itemtype? `Search Criteria for "${props.itemtype}"`:"What are you looking for?"}</span>} key="1">
                <WrappedInnerForm {...props} onSubmit={() => props.setVisible(false)} />
            </Collapse.Panel>
        </Collapse>

    );
}


const InnerForm = (props) => {

    const { getFieldDecorator } = props.form;
    const { formdata, loading, setSubmit, submit } = useFormSubmit(props.firebase, 'reviews', props.uid, props.form, `/lastsearch`);
    const [formloading, setFormloading] = useState(false);


    // const memoizedCallback = useCallback(
    //     () => {
    //         if (currentUser) {
    //             console.log("submit in memo:", submit);
    //             console.log(currentUser);
    //             currentUser.getIdToken().then(result => {
    //                 console.log('memoizedCallback token result:', result);
    //                 props.onTokenChange(result);
    //             });
    //         }
    //     },
    //     [submit],
    // );

    // memoizedCallback();

    const onSubmit = async (e) => {
        setFormloading(true);
        e.preventDefault();
        setSubmit(submit + 1);
        props.onSubmit();
        setFormloading(false);

    }

    useEffect(() => {
        if (submit > 0) {
            props.onFormDataChange(formdata);
        }
        return () => {

        };
    }, [formdata]);


    return (

        <Tabs type="card">
        <TabPane tab="Basic Criteria" key="1">
        
        <Form onSubmit={onSubmit}>
            {/* <Form.Item

                label="Item"
            >
            </Form.Item> */}
            <span className="ant-form-text">Type of item and its purpose: </span>
            <div style={{ minHeight: 60, marginTop: 20 }}>

                <Input.Group style={{ width: '100%' }}>
                    <Row type="flex" gutter={6}>
                        <Col span={10}>
                            <Form.Item required={false}
                            >
                                {getFieldDecorator('itemtype', {
                                    validateTrigger: ['onBlur'],
                                    rules: [{ required: true, message: ' ' }],
                                    initialValue: props.initialData? props.initialData.itemtype:null
                                })(
                                    <AutoComplete
                                        dataSource={itemtypes}
                                        style={{ width: '100%' }}
                                        // onSelect={(value) => this.onSelect("country")(value)}
                                        defaultOpen={false}
                                        // open={false}
                                        // autoFocus={true}
                                        // onSearch={this.handleSearch}
                                        placeholder="Select item type"
                                        filterOption={(inputValue, option) => inputValue.length >= 2 ? option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item required={false}
                            >
                                {getFieldDecorator('use', {
                                    validateTrigger: ['onBlur'],
                                    rules: [{ required: true, message: ' ' }],
                                    initialValue: props.initialData? props.initialData.use:null
                                })(
                                    <Select
                                        mode="default"
                                        style={{ width: '100%' }}
                                        placeholder="Select use"
                                    // defaultValue={['a10', 'c12']}
                                    // onChange={handleMultiSelectChange}
                                    >
                                        {purposeChildren}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                </Input.Group>
            </div>

            {/* <Form.Item

                label="Price (in US$)"
            >
                <span className="ant-form-text">The approximate price range (in US$) you are looking for:</span>
            </Form.Item> */}



            <span className="ant-form-text">The approximate price range (in US$) you are looking for:</span>

            <div style={{ minHeight: 100, marginTop: 20 }}>

                <Row type="flex" gutter={12}>
                    <Col span={10}>
                        <Form.Item
                            extra="min price"
                        >
                            {getFieldDecorator('minprice', {
                                initialValue: props.initialData? props.initialData.minprice:null
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    precision={0}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            extra="max price"
                        >
                            {getFieldDecorator('maxprice', {
                                initialValue: props.initialData? props.initialData.maxprice:null
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    precision={0} />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>

            <Form.Item>
                <p>Choose a type of listing: &nbsp; 
                            <Tooltip title="We selectively show items based on your search criteria and what we know about your style preference. 
                            Our knowledge about your style preference is based on a few factors, such as your answers to the Style Quiz, 
                            the lists of items you like or dislike or already own,
                            whether you've seen these items before, and your notes on these or similar items.">
                                <Icon type="question-circle-o" />
                            </Tooltip></p>
                            {getFieldDecorator('cue', {
                                initialValue: cues[2]
                            })(
                <RadioGroup size="small">
                    <RadioButton value={cues[0]} disabled>
                  <Tooltip title={`Recommend ${cues[0]} items based on your search criteria and what we know about your style preference.
                  This is not available if you've not provided enough information about your style preference yet.`}>
                Cue {cues[0]}
              </Tooltip></RadioButton>
                    <RadioButton value={cues[1]}>
                  <Tooltip title={`Choose ${cues[1]} items most likely to your liking. This requires less information on your style than Cue ${cues[0]}.`}>
                  Cue {cues[1]}
              </Tooltip></RadioButton>
                    <RadioButton value={cues[2]}>
                  <Tooltip title={`List ${cues[2]} items for you to review. This provides the most flexiblity to you and require the least information about your style.`}>
                  Cue {cues[2]}
              </Tooltip></RadioButton>
                </RadioGroup>
                )}
            </Form.Item>

            <p>Take notes while reviewing items?</p>
            <Switch
                checked={props.notesmode}
                onClick={props.onClickSwitch}
                checkedChildren={<Icon type="check" />}
                unCheckedChildren={<Icon type="close" />}
            />

            <Form.Item>
                <Button style={{ width: "33%", float: "right" }} type="primary" htmlType="submit" loading={formloading}>
                    OK
            </Button>
            </Form.Item>

        </Form >
        
        
        </TabPane>
        <TabPane tab="More..." key="2">Content of Tab Pane 2</TabPane>
      </Tabs>


        
    );
}

const WrappedInnerForm = Form.create({ name: 'review_items' })(InnerForm);

// const ExtendedForm = compose(
//     withRouter,
//     withFirebase,
// )(FormDrawer);

// const ExtendedSearchResults = compose(
//     withRouter,
//     withFirebase,
// )(SearchResults);


const PageBase = (props) => {

    const lastSearch = useFirebase(props.firebase, 'reviews', props.authUser.uid, `/lastsearch`);

    const notes = useModal();

    const { metadata, loading, error, setBody } = useEsearch(props.firebase);
    const [item, setItem] = useState({});
    const userp = useFirebase(props.firebase, 'userpieces', props.authUser.uid);
    const usero = useFirebase(props.firebase, 'userowns', props.authUser.uid);
    const [modaltype, setModaltype] = useState(null);
    const [notesmode, setNotesmode] = useState(true);
    const { visible, setVisible } = useModal(false);
    // const [itemtype, setItemtype] = useState(null);

    const { setData } = useUpdateDb(props.firebase, 'userp', props.authUser.uid);
    const updateown = useUpdateDb(props.firebase, 'usero', props.authUser.uid);
    const { setKeyname } = useRemoveDb(props.firebase, 'userp', props.authUser.uid);
    const removeown = useRemoveDb(props.firebase, 'usero', props.authUser.uid);

    const [like, setLike] = useState([]);
    const [dislike, setDislike] = useState([]);
    const [own, setOwn] = useState([]);
    const [hits, setHits] = useState([]);
    const [hitids, setHitids] = useState([]);
    const [formdata, setFormdata] = useState(null);
    const [offset, setOffset] = useState(0);
    const [genloading, setGenloading] = useState(false);


    const onClickSwitch = () => {
        notesmode ?
            message.success("Will not show popups for taking notes on these items.") :
            message.success("Turning on \"notes\" mode. There will be notes-taking popups.");
        setNotesmode(!notesmode);

    }

    const populateLast = async () => {

        if (Object.keys(lastSearch.data).length) {

            setFormdata(lastSearch.data);

            const newbody = bodybuilder()
                .query('match', 'piece_type', lastSearch.data.itemtype)
                .filter('range', 'price', { gte: lastSearch.data.minprice, lte: lastSearch.data.maxprice })
                .size(12)
                .build();

            setBody(newbody);
        } else {
            message.warning("No search on record. Please specify new criteria.")
        }

        setVisible(false);


    }


    const onFormDataChange = async (formdata) => {

        await Promise.all([setHits([]), setHitids([]), setOffset(0)]);

        setFormdata(formdata);

        const newbody = bodybuilder()
            .query('match', 'piece_type', formdata.itemtype)
            .filter('range', 'price', { gte: formdata.minprice, lte: formdata.maxprice })
            .size(Math.min(formdata.cue, loadsize))
            .build();

        setBody(newbody);

    }

    const onNotesFormDataChange = (newformdata) => {

        console.log(newformdata);
        // setFormdata(newformdata);
        // const newbody = bodybuilder()
        //     .query('match', 'piece_type', newformdata.itemtype)
        //     .filter('range', 'price', { gte: newformdata.minprice, lte: newformdata.maxprice })
        //     .size(60)
        //     .build();

        // doGet(newbody);
    }

    const onShowItem = (type, source) => {
        setModaltype(type);
        setItem(source);
        notes.showModal();
    }

    const onNotesSubmit = () => {
        console.log("notes submitted");
        notes.setVisible(false);
    }

    const onClick = (type, source) => {

        switch (type) {
            case 'like':
                if (like.includes(source.pid)) {
                    setLike(like.filter(i => i !== source.pid));
                    setKeyname(source.pid);
                } else {
                    setLike([...like, source.pid]);
                    setDislike(dislike.filter(i => i !== source.pid));
                    setData({ [source.pid]: true });
                    if (notesmode) { onShowItem(type, source) };
                }
                break;
            case 'dislike':
                if (dislike.includes(source.pid)) {
                    setDislike(dislike.filter(i => i !== source.pid));
                    setKeyname(source.pid);
                } else {
                    setDislike([...dislike, source.pid]);
                    setLike(like.filter(i => i !== source.pid));
                    setData({ [source.pid]: false });
                    if (notesmode) { onShowItem(type, source) };
                }
                break;
            case 'own':
                if (own.includes(source.pid)) {
                    setOwn(own.filter(i => i !== source.pid));
                    removeown.setKeyname(source.pid);
                } else {
                    setOwn([...own, source.pid]);
                    updateown.setData({ [source.pid]: true });
                    if (notesmode) { onShowItem(type, source) };
                }
                break;
            default:
                console.log("not a valid click value");
        }
    }

    const onPagination = () => {

        setGenloading(true);

        setOffset(offset+1);

        const newbody = bodybuilder()
        .query('match', 'piece_type', formdata.itemtype)
        .filter('range', 'price', { gte: formdata.minprice, lte: formdata.maxprice })
        .size(Math.min(formdata.cue, loadsize))
        .from(loadsize * offset)
        .build();

        setBody(newbody);

    }


    useEffect(() => {

        if (Object.keys(userp.data).length > 0) {

        const likes = Object.entries(userp.data).filter(i => i[1]===true).map(i => i[0]);
        const dislikes = Object.entries(userp.data).filter(i => i[1]===false).map(i => i[0]);

        if (likes.length > 0) {
            setLike(likes)
        }

        if (dislikes.length > 0) {
            setDislike(dislikes)
        }

        }

        if (Object.keys(usero.data).length > 0) {
    
        const owns = Object.entries(usero.data).map(i => i[0]);

        if (owns.length > 0) {
            setOwn(owns)
        }

        }
        

        return () => {
        };

    }, [usero.data, userp.data]);


    useEffect(() => {
        //this updates the hits (search results) to display, if metadata changes
        if (metadata && metadata.hits && metadata.hits.length > 0) {
            const newhits = metadata.hits.filter(i => !hitids.includes(i._source.pid));
            setHits([...hits, ...newhits]);
            setHitids([...new Set([...hits, ...newhits].map(i => i._source.pid))])
            // if (hits.length < 96) {
            // setHits([...hits, ...newhits]);
            // setHitids([...new Set([...hits, ...newhits].map(i => i._source.pid))])
            // }else{
            //     const oldhits = hits.slice(-newhits.length);
            //     setHits([...oldhits, ...newhits]);
            //     setHitids([...new Set([...oldhits, ...newhits].map(i => i._source.pid))]);
            //     window.scrollTo(0, document.body.offsetTop);
            // }
           
        }

        setGenloading(false);

        return () => {
        };

    }, [metadata]);


    const onLoadMore = async () => {

        setGenloading(true);

        await Promise.all([setHits([]), setHitids([]), setOffset(offset+1)]);

        setFormdata(formdata);

        const newbody = bodybuilder()
            .query('match', 'piece_type', formdata.itemtype)
            .filter('range', 'price', { gte: formdata.minprice, lte: formdata.maxprice })
            .size(Math.min(formdata.cue, loadsize))
            .from(loadsize * offset)
            .build();

        setBody(newbody);

        window.scrollTo(0, 0);

    }


    return (
        <React.Fragment>
            <div style={{ margin: "auto" }}>

                <FormDrawer
                    uid={props.authUser.uid}
                    onFormDataChange={onFormDataChange}
                    onClickSwitch={onClickSwitch}
                    notesmode={notesmode}
                    visible={visible}
                    setVisible={setVisible}
                    itemtype={(formdata && formdata.itemtype) || null}
                    initialData={lastSearch.data}
                    {...props} />
                <SearchResults
                    authUser={props.authUser}
                    metadata={metadata}
                    // hits={(metadata && metadata.hits) || []}
                    hits={hits}
                    loading={loading}
                    onShowItem={onShowItem}
                    populateLast={populateLast}
                    notesmode={notesmode}
                    genloading={genloading}
                    description={<span>
                        Specify Some Criteria above, or <a href="# " onClick={populateLast}>Use the last set of Criteria</a> to Start Reviewing Items
                        </span>}
                    like={like}
                    dislike={dislike}
                    own={own}
                    onClick={onClick}
                    onPagination={onPagination}
                    offset={offset}
                    onLoadMore={onLoadMore}
                    cue={(formdata && formdata.cue) || 0}
                    {...props} 
                    />
                <NotesModal
                    uid={props.authUser.uid}
                    visible={notes.visible}
                    handleCancel={notes.cancelModal}
                    onFormDataChange={onNotesFormDataChange}
                    item={item}
                    type={modaltype}
                    onSubmit={onNotesSubmit}
                    // onTokenChange={onTokenChange}
                    {...props} />

            </div>
            <div>
                <BackTop />
            </div>
        </React.Fragment>
    )
}


const ExtendedPageBase = compose(
    withRouter,
    withFirebase,
)(PageBase);


const ReviewItems = (props) => {
    // const {token, setToken} = useState(null);

    // const visible = useSignup();

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <ExtendedPageBase authUser={authUser} />
            )}
        </AuthUserContext.Consumer>

    );
}


const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withQuiz,
)(ReviewItems);

export { Description, Likes };



