import axios from 'axios';
import { useCancellablePromise } from '../Hooks';
// const USER_CREATION_URL = 'http://localhost:8010/inlo-10884/us-central1/api/signup';
const USER_CREATION_URL = process.env.NODE_ENV === 'production'
? 'https://us-central1-inlo-10884.cloudfunctions.net/api/signup'
: 'http://localhost:8010/inlo-10884/us-central1/api/signup';


const CAPTCHA_VERIFICATION_URL = process.env.NODE_ENV === 'production'
? 'https://us-central1-inlo-10884.cloudfunctions.net/api/verify'
: 'http://localhost:8010/inlo-10884/us-central1/api/verify';


const QUIZ_LOGGING_URL = process.env.NODE_ENV === 'production'
  ? 'https://us-central1-inlo-10884.cloudfunctions.net/api/logquiz'
  : 'http://localhost:8010/inlo-10884/us-central1/api/logquiz';


export const createUser = (authuser, referrer, username, email) =>
    axios.post(USER_CREATION_URL,
        {
            uid: authuser.user.uid,
            referrer,
            username,
            email
        });

export const verifyCaptcha = (captchatoken) =>
axios.post(CAPTCHA_VERIFICATION_URL,
    {
        captchatoken
    });

// export const makeCancelable = (promise) => {
//         let hasCanceled_ = false;
      
//         const wrappedPromise = new Promise((resolve, reject) => {
//           promise.then(
//             val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
//             error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
//           );
//         });
      
//         return {
//           promise: wrappedPromise,
//           cancel() {
//             hasCanceled_ = true;
//           },
//         };
//       };   

export const logQuiz = (data, uid) =>
axios.post(QUIZ_LOGGING_URL, {
data,
uid
});
