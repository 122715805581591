import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import queryString from 'query-string';
import Recaptcha from 'react-recaptcha';
import {
    Form, Input, Tooltip, Icon, Checkbox, Button, Modal, message, Alert
} from 'antd';

import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
// import regForm from './form';
import {createUser, verifyCaptcha} from '../Functions';

import styles from '../../index.css';

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this email address already exists.
  Try to login with that account instead. If you think the
  email is already used from one of the social logins, try
  to sign in with one of them. Afterwards, link your accounts
  on your personal account page.
`;

const INITIAL_STATE = {
    error: null,
    confirmDirty: false,
    loading: false,
    alert: false,
    captcha: false,
    // captchatoken: null,
};

// define a variable to store the recaptcha instance
let recaptchaInstance;

// const RecaptchaItem = () => {

//     const verifyCallback = (result) => {
//     if (result) {
//       console.log('verifyCallback', result);
//       this.props.onChange(result);
//       console.log(this.props.onChange);
//     }
//     };

//     return <Recaptcha
//       ref={e => recaptchaInstance = e}
//       sitekey={sitekey}
//       // size="compact"
//       render="explicit"
//       verifyCallback={verifyCallback}
//       onloadCallback={() => {}}
//       // expiredCallback={expiredCallback}
//     />;
//     };


const sitekey = '6Le3VpIUAAAAALA2sq8UumXjPXo0euFuJkkUS9PY';

// specifying your onload callback function
// const callback = () => {
//     console.log('loaded');
// };



// const expiredCallback = () => {
//   console.log(`Recaptcha expired`);
// };



class RegistrationForm extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    callback = () => {
        console.log('loaded');
    };

    verifyCallback = (response) => {

        if (response) {
            verifyCaptcha(response)
            .then(res => {
                if (res.status === 200) {
                this.setState({captcha: true});
                }
                console.log(res);
            })

        }
    };

    resetRecaptcha = () => {
        recaptchaInstance.reset();
      };
      

    onClose = () => {
        this.setState({ alert: false });
    };


    handleCancel = () => {
        this.setState({  ...INITIAL_STATE });
        this.props.form.resetFields();
        console.log("reset fields");
        this.resetRecaptcha();
        this.props.handleCancel();
    };


    onSubmit = async (e) => {
        const form = this.props.form;
        const email = form.getFieldValue('email');
        const password = form.getFieldValue('password');
        const username = form.getFieldValue('nickname');
        // const captchatoken = this.state.captchatoken;

        const params = this.props.location.search;
        const refer = (params && queryString.parse(params).refer) || '';
        const captcha = this.state.captcha;

        e.preventDefault();

        if (!captcha) {
            message.error("Please check the reCaptcha");
            return;
        }

        await this.setState({ loading: true });

        console.log('refer:', refer);

        this.props.form.validateFields((err, values) => {
            if (!err) {

                this.props.firebase
                    .doCreateUserWithEmailAndPassword(email, password)
                    .then(authUser => createUser(authUser, refer, username, email))
                    .then(() => {
                        this.props.firebase.doSendEmailVerification();
                        message.success("A verification email has been sent to you", 1.5);
                        this.props.history.push(ROUTES.QUIZ);
                        this.handleCancel();
                    })
                    .catch(error => {
                        console.log('update error', error);
                        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                            this.setState({ alert: true });
                            // error.message = ERROR_MSG_ACCOUNT_EXISTS;
                            console.log(error.message);
                        } else { message.error(error.message,2); }
                        // this.setState({ error });
                        this.setState({ ...INITIAL_STATE});
                    });
            } else {
                this.setState({ loading: false });
                message.error('Please check your entries for error', 1);
                console.log("here's the validation error:", err);
            }
        });


    }




    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('The passwords don\'t match');
            // this.setState({submitDisabled: true});
        } else {
            // this.setState({submitDisabled: false});
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    }



    render() {
        const { getFieldDecorator } = this.props.form;

        // const formItemLayout = {
        //     labelCol: {
        //         xs: { span: 24 },
        //         sm: { span: 8 },
        //     },
        //     wrapperCol: {
        //         xs: { span: 24 },
        //         sm: { span: 16 },
        //     },
        // };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        const PossibleAlert = () => this.state.alert ?
            <Alert
                message="Duplicate Account"
                description={ERROR_MSG_ACCOUNT_EXISTS}
                type="error"
                closable={true}
                onClose={this.onClose}
                banner={true}
            /> : <div></div>

        return (


            <Modal
                visible={this.props.visible}
                title="Create a new account"
                onCancel={this.handleCancel}  //remove this so that pressing esc doesn't bring in the close of the modal
                footer={null}
                closable={true}
                maskClosable={false}
            >
                <PossibleAlert />
                <Form onSubmit={this.onSubmit} className={styles["login-form"]}>
                    <Form.Item
                        // {...formItemLayout}
                        label="E-mail"
                        extra="A verification email will be sent to this address"
                    >
                        {getFieldDecorator('email', {
                            validateTrigger: ['onBlur'],
                            rules: [{
                                type: 'email', message: 'Not a valid email address',
                            }, {
                                required: true, message: ' ',
                            }],
                        })(
                            <Input allowClear name="email" onChange={this.onChange} />
                        )}
                    </Form.Item>
                    <Form.Item
                        // {...formItemLayout}
                        label={(
                            <span>
                                Password&nbsp;
                <Tooltip title="Use letters, numbers and special characters">
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </span>
                        )}
                    >
                        {getFieldDecorator('password', {
                            validateTrigger: ['onBlur'],
                            rules: [{
                                required: true, message: 'Password must be at least 6 characters', min: 6,
                            }, {
                                validator: this.validateToNextPassword,
                            }],
                        })(
                            <Input.Password name="passwordOne" type="password" onChange={this.onChange} />
                        )}
                    </Form.Item>
                    <Form.Item
                        // {...formItemLayout}
                        label="Confirm Password"
                    >
                        {getFieldDecorator('confirm', {
                            validateTrigger: ['onBlur'],
                            rules: [{
                                required: true, message: 'Please input your password again',
                            }, {
                                validator: this.compareToFirstPassword,
                            }],
                        })(
                            <Input.Password name="passwordTwo" type="password" onBlur={this.handleConfirmBlur} onChange={this.onChange} />
                        )}
                    </Form.Item>
                    <Form.Item
                        // {...formItemLayout}
                        label={(
                            <span>
                                Nickname&nbsp;
                <Tooltip title="What do you want others to call you?">
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </span>
                        )}
                    >
                        {getFieldDecorator('nickname', {
                            validateTrigger: ['onBlur'],
                            rules: [{ required: true, message: ' ', whitespace: true }],
                        })(
                            <Input allowClear onChange={this.onChange} />
                        )}
                    </Form.Item>

                    <Form.Item 
                        // {...formItemLayout}
                        // label="Captcha"
                    >
                    <Recaptcha 
                        ref={e => recaptchaInstance = e}
                        sitekey={sitekey}
                        // size="compact"
                        render="explicit"
                        verifyCallback={this.verifyCallback}
                        onloadCallback={this.callback}
                        theme = "light"
                        // expiredCallback={expiredCallback}
                        />

                    </Form.Item>

                    <Form.Item
                        {...tailFormItemLayout}
                    >
                        {getFieldDecorator('agreement', {
                            valuePropName: 'checked',
                            validateTrigger: ['onBlur'],
                            rules: [{
                                required: true,
                                transform: value => (value || undefined),
                                type: 'boolean',
                                message: 'Agree to proceed'
                            }],
                        })(
                            <Checkbox onChange={this.onChange}>I agree to the <a href=" #">Terms</a></Checkbox>
                        )}
                    </Form.Item>



                    <Form.Item
                        {...tailFormItemLayout}
                    >
                        <Button type="primary" htmlType="submit" loading={this.state.loading}>Sign Up</Button>
                    </Form.Item>

                </Form>
            </Modal>

        );
    }
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(RegistrationForm);

// export default WrappedRegistrationForm;









const SignUpForm = compose(
  withRouter,
  withFirebase,
)(WrappedRegistrationForm);


export default SignUpForm;

// export { SignUpForm, SocialDisclaimer };