import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

import {
    message, Steps, Divider, Popover, Col, Row
} from 'antd';

// import PropTypes from 'prop-types';
// import Autosuggest from 'react-autosuggest';

import { AuthUserContext, withAuthorization } from '../Session';

import WrappedUserFormBase from './userForm';
import WrappedDynamicFieldSet from './shoppingForm';
import WrappedAttributesFormBase from './attributesForm';
import WrappedStylesFormBase from './stylesForm';
// import WrappedSizeForm from './sizeForm';


const Step = Steps.Step;

const timexp = {0: 1, 1: 2, 2:2, 3: 1}


const customDot = (dot, { status, index }) => (
    // <Popover content={<span>step {index+1} status: {status}</span>}>
    <Popover content={status==='finish'? <span>Done</span> : <span>~ {timexp[index]} min</span>}>
        {dot}
    </Popover>
);





// function countDown() {
//     let secondsToGo = 10;
//     const modal = Modal.success({
//         title: 'These questions help us find the best stuff for you',
//         content: `Answering them usually takes less than 5 minutes.`,
//     });
//     setTimeout(() => {
//         modal.destroy();
//     }, secondsToGo * 1000);
// }

class Questionnaire extends Component {

    constructor(props) {
        super(props);

        this.state = { page: 0};
        // countDown();

        this.carousel = React.createRef();
    }

    // next = async (currentPage) => {
    //     const slide = this.carousel.innerSlider.state.currentSlide;
    //     await this.setState({test: slide+1});
    //     console.log(this.state.page);
    //     this.carousel.next();
    // }

    // prev = () => {
    //     this.carousel.prev();
    //     // this.setState((state) => ({ page: state.page - 1 }));
    // }

    // afterChange = async () => {

    //     const page = this.carousel.innerSlider.state.currentSlide;

    //     if (page > this.state.page) {
    //         message.error("Please fill out the answers and press \"next\" to proceed", 2)
    //         console.log("same");
    //         await this.setState({ test: this.state.test + 1 });
    //         this.carousel.goTo(this.state.page);
    //         // this.carousel.prev();
    //         console.log(page);
    //     }
    // }

    // onSubmit = async () => {

    //     const page = this.carousel.innerSlider.state.currentSlide;
    //     await this.setState({page: page+1});
    //     console.log(this.state.page);
    //     this.carousel.next();

    // };

    onSubmit = (pagen) => {

        // const page = this.carousel.innerSlider.state.currentSlide;
        this.setState({ page: pagen + 1 });
        // console.log(this.state.page);
        // this.carousel.next();

    };

    render() {


        // const SwitchForm = (props) => {

        //     switch (props.page) {
        //         case 0:
        //         console.log("this page is:", props.page);
        //         console.log('this state page is:', this.state.page);
        //         return this.state.page===0? <UserForm uid={props.uid} onSubmit={() => this.onSubmit(0)} refname="userbasic"/> : <div></div>
        //         case 1:
        //           return this.state.page===1? <AttributesForm uid={props.uid} onSubmit={() => this.onSubmit(1)} refname="userav"/> : <div></div>
        //         case 2:
        //           return this.state.page===2? <StylesForm uid={props.uid} onSubmit={() => this.onSubmit(2)} refname="userpref"/> : <div></div>
        //         case 3:
        //           return this.state.page===3? <ShoppingForm uid={props.uid} onSubmit={() => this.onSubmit(3)} refname="userav"/> : <div></div>
        //         default:
        //           return null;
        //       }

        // };

        const SwitchForm = (props) => {

            switch (this.state.page) {
                case 0:
                    return <UserForm uid={props.uid} onSubmit={() => this.onSubmit(0)} refname="userbasic" />
                case 1:
                    return <AttributesForm uid={props.uid} onSubmit={() => this.onSubmit(1)} refname="userav" />
                case 2:
                    return <StylesForm uid={props.uid} onSubmit={() => this.onSubmit(2)} refname="userpref" />
                case 3:
                    return <ShoppingForm uid={props.uid} onSubmit={() => this.onSubmit(3)} />
                case 4:
                    return <div style={{marginTop: 80, maxWidth:400, margin: "auto", lineHeight: "200px"}}><h3>Thank you! We've recorded your preferences.</h3></div>
                default:
                    return null;
            }

        };



        // const props = {
        //     dots: true,
        //     infinite: false,
        //     speed: 500,
        //     slidesToShow: 1,
        //     slidesToScroll: 1
        // };

        // const prev = <Icon type="left-circle" onClick={this.previous} style={{ fontSize: 40 }} />

        return (
    
                <div style={{ paddingLeft: 24, paddingRight: 24 }}> 
                    <Row type="flex" justify="center">
                        <Col xs={24} sm={20} md={16} lg={12} xl={10} xxl={8}>
                            <h1>Tell us a little about yourself</h1>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center">
                    <Col xs={24} sm={20} md={16} lg={12} xl={10} xxl={8}>
                            <Divider />
                        </Col>
                    </Row>
                    <Row type="flex" justify="center">
                    <Col xs={20} sm={20} md={16} lg={12} xl={10} xxl={8}>
                            <Steps current={this.state.page} progressDot={customDot} size={'small'}>
                                <Step title="Basics" />
                                {/* description="hover"  */}
                                <Step title="Specific Styles" />
                                <Step title="General Styles" />
                                <Step title="Preferences" />
                            </Steps>
                        </Col>
                    </Row>
                    {/* <Divider orientation="left">Questions</Divider> */}

                    <AuthUserContext.Consumer>
                        {authUser => (
                            <Row type="flex" justify="center">
                                <Col xs={20} sm={20} md={16} lg={12} xl={10} xxl={8}>
                                    <SwitchForm page={this.state.page} uid={authUser.uid} onSubmit={this.onSubmit} />
                                </Col>
                            </Row>
                            //     <Row>


                            //         <Carousel ref={node => (this.carousel = node)} {...props} afterChange={this.afterChange} >
                            //             <div> 
                            //                 <UserForm uid={authUser.uid} onSubmit={() => this.onSubmit(0)} onPrev={this.previous} refname="userbasic"/> 
                            //             </div> 
                            //             <div>
                            //                 <AttributesForm uid={authUser.uid} onSubmit={() => this.onSubmit(1)} onPrev={this.previous} refname="userav"/>
                            //             </div>
                            //             <div>
                            //                 <StylesForm uid={authUser.uid} onSubmit={() => this.onSubmit(2)} onPrev={this.previous} refname="userpref"/>
                            //             </div>
                            //             <div>
                            //                 <ShoppingForm uid={authUser.uid} onSubmit={() => this.onSubmit(3)} onPrev={this.previous} refname="userav"/>
                            //             </div>
                            //         </Carousel>


                            //     </Row>

                            // </div>


                        )}
                    </AuthUserContext.Consumer>

               </div> 

        );
    }
}





const UserForm = compose(
    withRouter,
    withFirebase,
)(WrappedUserFormBase);


const ShoppingForm = compose(
    withRouter,
    withFirebase,
)(WrappedDynamicFieldSet);


const AttributesForm = compose(
    withRouter,
    withFirebase,
)(WrappedAttributesFormBase);

const StylesForm = compose(
    withRouter,
    withFirebase,
)(WrappedStylesFormBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Questionnaire);

// export { QuestionnarePage };