import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import queryString from 'query-string';
import {
  Form, Icon, Input, Button, Checkbox, Modal, message, Alert
} from 'antd';

import { withFirebase } from '../Firebase';
import { createUser } from '../Functions';

import styles from '../../index.css';

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this email address already exists. 
  It may be a regular password login account, or a social login account. 
  Try to sign in with that account instead. Afterwards, you can link your accounts
  on your personal account page.
`;

const INITIAL_STATE = {
  error: null,
  loading: false,
  alert: false,
};



class NormalLoginForm extends Component {

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };


  }

  handleCancel = () => {
    this.setState({ alert: false });
    this.props.form.resetFields();
    // console.log("reset fields");
    this.props.handleCancel();
  };



  onSubmit = async (e) => {

    const form = this.props.form;
    const email = form.getFieldValue('email');
    const password = form.getFieldValue('password');

    e.preventDefault();

    await this.setState({ loading: true });

    this.props.form.validateFields((err, values) => {
      if (!err) {

        this.props.firebase
          .doSignInWithEmailAndPassword(email, password)
          // .then(() => )
          // // .then(() => setTimeout(() => { console.log("wait") }, 2500))
          .then(() => {
            this.handleCancel();
            this.setState({ ...INITIAL_STATE });
            // this.props.history.push(ROUTES.HOME);
          })
          .catch(error => {
            this.setState({ error, loading: false });
            console.log('update error', error);
            message.error(error.message, 2);
          });

      } else {
        this.setState({ loading: false });
        message.error('Please check your entries for error', 1);
        console.log("here's the validation error:", err);
      }
    });

  }

  onSubmitGoogle = async event => {
    const params = this.props.location.search;
    const refer = params && queryString.parse(params).refer;

    event.preventDefault();

    await this.setState({ loading: true });

    this.props.firebase
      .doSignInWithGoogle()
      // .then(socialAuthUser => {
      //   // Create a user in your Firebase Realtime Database too
      //   return this.props.firebase
      //     .user(socialAuthUser.user.uid)
      //     .set({
      //       username: socialAuthUser.user.displayName,
      //       email: socialAuthUser.user.email,
      //     });
      // })
      .then(socialAuthUser => createUser(socialAuthUser, refer, socialAuthUser.user.displayName, socialAuthUser.user.email))
      .then(() => {
        this.handleCancel();
        this.setState({ ...INITIAL_STATE });
        // this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error, loading: false });
        console.log('update error', error);
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          this.setState({ alert: true });
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
          console.log(error.message);
        } else { message.error(error.message, 2); }
      });
  };


  onSubmitFacebook = async event => {
    const params = this.props.location.search;
    const refer = params && queryString.parse(params).refer;

    event.preventDefault();

    await this.setState({ loading: true });

    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => createUser(socialAuthUser, refer, socialAuthUser.additionalUserInfo.profile.name, socialAuthUser.additionalUserInfo.profile.email))
      .then(() => {
        this.handleCancel();
        this.setState({ ...INITIAL_STATE });
        // this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error, loading: false });
        console.log('update error', error);
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          this.setState({ alert: true });
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
          console.log(error.message);
        } else { message.error(error.message, 2); }
      });


  };

  onSubmitTwitter = async event => {
    const params = this.props.location.search;
    const refer = params && queryString.parse(params).refer;

    event.preventDefault();

    await this.setState({ loading: true });

    this.props.firebase
      .doSignInWithTwitter()
      // .then(socialAuthUser => {
      //   // Create a user in your Firebase Realtime Database too
      //   return this.props.firebase.user(socialAuthUser.user.uid).set({
      //     username: socialAuthUser.additionalUserInfo.profile.name,
      //     email: socialAuthUser.additionalUserInfo.profile.email,
      //     // roles: [],
      //   });
      // })
      .then(socialAuthUser => createUser(socialAuthUser, refer, socialAuthUser.additionalUserInfo.profile.name, socialAuthUser.additionalUserInfo.profile.email))
      .then(() => {
        this.handleCancel()
        this.setState({ ...INITIAL_STATE });
        // this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error, loading: false });
        console.log('update error', error);
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          this.setState({ alert: true });
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
          console.log(error.message);
        } else { message.error(error.message, 2); }
      });

  };




  handleSignup = event => {
    event.preventDefault();
    this.handleCancel();
    this.props.handleSignup();
  }

  handlePwforget = event => {
    event.preventDefault();
    this.handleCancel();
    this.props.handlePwforget();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const PossibleAlert = () => this.state.alert ?
      <Alert
        message="Duplicate Account"
        description={ERROR_MSG_ACCOUNT_EXISTS}
        type="error"
        closable={true}
        onClose={this.onClose}
        banner={true}
      /> : <div></div>

    //   getFieldDecorator gives a field its id. e.g. 'email', then id='normal_login_email'

    return (

      // <Button onClick={this.props.handleCancel}>Click me</Button>

      <Modal
        visible={this.props.visible}
        title="Sign in"
        onCancel={this.handleCancel}
        footer={null}
        closable={true}
        maskClosable={false}
        width={440}
      >
        <PossibleAlert />
        <Form onSubmit={this.onSubmit} className={styles["login-form"]} >
          {/* style={{maxWidth: '300px',margin: 'auto'}} */}
          <Form.Item>
            {getFieldDecorator('email', {
              validateTrigger: ['onBlur'],
              rules: [
                { type: 'email', message: 'Not a valid email address' },
                { required: true, message: ' ' }],
            })(
              <Input allowClear name="email" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email address" onChange={this.onChange} />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: ' ' }],
            })(
              <Input.Password name="password" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" onChange={this.onChange} />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(
              <Checkbox>Remember me</Checkbox>
            )}
            <Link className="login-form-forgot" to="#" onClick={this.handlePwforget}>Forgot Password</Link>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
              Log in
            </Button>

            Don't have an account?&nbsp;
              <Link to="#" onClick={this.handleSignup}>&nbsp;Sign up</Link>
          </Form.Item>
          <span style={{ margin: "auto" }}> Log in with&nbsp;&nbsp;
                            <Icon style={{ fontSize: "18px" }} type="google" onClick={this.onSubmitGoogle} />&nbsp;&nbsp;
                            <Icon style={{ fontSize: "18px" }} type="facebook" theme="filled" onClick={this.onSubmitFacebook} />&nbsp;&nbsp;
                            <Icon style={{ fontSize: "18px" }} type="twitter" onClick={this.onSubmitTwitter} />
          </span>

        </Form>
      </Modal>

    );
  }
};

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);



const SignInForm = compose(
  withRouter,
  withFirebase,
)(WrappedNormalLoginForm);




export default SignInForm;
