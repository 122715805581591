import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Navigation from '../Navigation';
import LandingPage from '../Landing';
import ProfilePage, {LoginCredPage, ExtendMembershipPage, SettingsPage} from '../Account';
// import AdminPage from '../Admin';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import QuestionnairePage from '../Questionnaire';
import ReviewItems, {Description, Likes} from '../ReviewItems';
import Notfound from '../Notfound';
import Footer from '../Footer';

const App = () => (
  <Router>
<React.Fragment>
      <Navigation/>
      <hr/>
      <div style={{minHeight: 800}}>
      <Switch>
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      {/* <Route path={ROUTES.LANDING+'?refer=:refer'} component={LandingPage} /> */}

      <Route path={ROUTES.LIKES} component={Likes} />
      <Route path={ROUTES.PROFILE} component={ProfilePage} />
      {/* <Route path={ROUTES.ADMIN} component={AdminPage} /> */}
      <Route path={ROUTES.QUIZ} component={QuestionnairePage} />
      {/* <Route path={ROUTES.LOGINS} component={LoginCredPage} /> */}
      <Route path={ROUTES.EXTEND} component={ExtendMembershipPage} />
      <Route path={ROUTES.RECOMMEND} component={ReviewItems} />
      <Route path={ROUTES.DESCRIPTION} component={Description} />
      <Route path={ROUTES.SETTINGS} component={SettingsPage} />
      {/* <Route path="*" component={Notfound} /> */}
      <Redirect to="/" />
      </Switch>
      </div>
      <hr/>
      <Footer transparent />
      </React.Fragment>
  </Router>
);

export default withAuthentication(App);