import { useRef, useEffect, useState } from 'react';
import axios from 'axios';

import { message } from 'antd';

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  

export function useModal(init = false) {
    const [visible, setVisible] = useState(init);

    function cancelModal() {
        setVisible(false);
    }

    function showModal() {
        setVisible(true);
    }

    // useEffect(
    //     () => console.log("using Modal")
    // );

    return { visible, cancelModal, showModal, setVisible };
}


//   export function makeCancelable(promise) {
//     let isCanceled = false;
//     const wrappedPromise =
//       new Promise((resolve, reject) => {
//         promise
//           .then(
//             val => (isCanceled
//               ? reject(new Error({ isCanceled })) 
//               : resolve(val))
//           )
//           .catch(
//             error => (isCanceled
//               ? reject(new Error({ isCanceled }))
//               : reject(error))
//           );
//       });
//     return {
//       promise: wrappedPromise,
//       cancel() {
//         isCanceled = true;
//       },
//     };
//   }


export const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
            error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};




export default function useCancellablePromise(cancelable = makeCancelable) {
    const emptyPromise = Promise.resolve(true);

    // test if the input argument is a cancelable promise generator
    if (cancelable(emptyPromise).cancel === undefined) {
        throw new Error('promise wrapper argument must provide a cancel() function')
    }
    // think of useRef as member variables inside a hook
    // you cannot define promises here as an array because
    // they will get initialized at every render refresh
    const promises = useRef([]);
    // const promises = useRef();

    // useEffect initializes the promises array
    // and cleans up by calling cancel on every stored
    // promise.
    // Empty array as input to useEffect ensures that the hook is
    // called once during mount and the cancel() function called
    // once during unmount
    useEffect(
        () => {
            // initialization, if works... 
            promises.current = promises.current || [];
            // console.log("updated promises:", promises.current);

            // the cleanup
            return function cancel() {
                promises.current.forEach(p => p.cancel());
                promises.current = [];
            };
        }, []
    );


    // cancelablePromise remembers the promises that you
    // have called so far. It returns a wrapped cancelable
    // promise

    function cancellablePromise(p) {
        const cPromise = cancelable(p);
        promises.current.push(cPromise);
        return cPromise.promise;
    }

    return { cancellablePromise };

    // return { promises };
}


//useDb is replaced by useFirebase. Just retaining the code for reference
function useDb(firebase, refname, uid) {
    const [uinfo, setUinfo] = useState({});
    const [error, setError] = useState(null);
    // const pendingPromises = [];
    const { cancellablePromise } = useCancellablePromise();


    function clearError() {
        setError(null);
    }


    useEffect(() => {

        const ref = firebase[refname];
        const query = ref(uid);
        // const wrappedPromise = makeCancelable(query.once("value"));

        // const data = await cancellablePromise(getJson(url));

        console.log("ref:", ref);
        console.log("query:", query);

        cancellablePromise(query.once("value"))
            .then(snapshot => snapshot.val())
            .then(console.log("doing the fetching of data inside the chain..."))
            .then(termObject => {
                setUinfo(termObject);
                // removePendingPromise(wrappedPromise);
            })
            .catch(err => {
                if (!err.isCanceled) {
                    setError(err.error);
                    // removePendingPromise(wrappedPromise);
                }
            });

        // Specify how to clean up after this effect:
        return function cleanup() {
            // pendingPromises.map(p => p.cancel());
            clearError();
        };
    }, []

    );
    return [uinfo, error];
}



//this fetches data
export const useFirebase = (firebase, rootname, uid, childpath='') => {

    const query = firebase.db.ref(`${rootname}/${uid}${childpath}`);

    const { cancellablePromise } = useCancellablePromise();

    const [data, setData] = useState({});
    const [error, setError] = useState(null);

    // const [url, setUrl] = useState(
    //   'http://hn.algolia.com/api/v1/search?query=redux',
    // );
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setError(null);
        setLoading(true);

        try {
            const snapshot = await cancellablePromise(query.once("value"));
            setData(snapshot.val() || {});
            setLoading(false);
        } catch (err) {
            if (!err.isCanceled) { setError(err.error);
            message.error(err.message, 2);
            console.log("error fetching data:", err);
            setLoading(false);
         }
            else {
                console.log("data fetch is cancelled");
            }
        }


        // setLoading(false);
    };


    useEffect(() => {
        fetchData();
    }, []);

    // const doGet = event => {
    //   setUrl(`http://hn.algolia.com/api/v1/search?query=${query}`);
    //   event.preventDefault();
    // };

    return { data, loading, error };
}

// this handles frontend form submit data
export const useFormSubmit = (firebase, rootname, uid, form, childpath= '') => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(0);
    const [formdata, setFormdata] = useState({});

    const ref = firebase.db.ref(`${rootname}/${uid}${childpath}`);


    // const { uid, refname } = this.props;
    // const { country, province, city, gender, yob } = this.state;
    // const udObject = Object.assign(...fieldlist.map(i => ({ [i]: form.getFieldValue(i) })));

    // e.preventDefault();

    const postData = async () => {

        if (submit) {
            setError(null);
            setLoading(true);

            form.validateFields((err, values) => {

                if (!err) {
                        setFormdata(values);

                        ref.update(values)
                            .catch(error => {
                                setError(error);
                                console.log('update error', error);
                                message.error(error.message, 2);
                            });
                

                    //   this.props.onSubmit();

                } else {
                    message.error('Please check your entries for error', 1);
                    console.log("here's the validation error:", err);
                }
            });


        }
        // try {
        //     const snapshot = await cancellablePromise(query.once("value"));
        //     setData(snapshot.val() || {});
        // } catch (err) {
        //     if (!err.isCanceled) { setError(err.error);}
        //     message.error(err.message, 2);
        // }

        setLoading(false);

    }


    useEffect(() => {
        postData();
    }, [submit]);


    return { formdata, loading, error, setSubmit, submit };
}


export const useUserToken = (authUser) => {


    const [token, setToken] = useState(null);

    const getToken = async () => {
        // firebase.auth().currentUser.getIdToken(true)
        if (authUser) {
            console.log(authUser);
            authUser.getIdToken()
                .then(result => {
                    console.log('result:', result);
                    setToken(result);
                });
        }
    }

    useEffect(() => {
        getToken();
    }, []);

    return { token, getToken };

}


export const useEsearch = (firebase) => {

    // // const {token, getToken} = useUserToken(authUser);
    // const [token, setToken] = useState(inittoken);
    const [body, setBody] = useState({});
    const [metadata, setMetadata] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    // const url = process.env.NODE_ENV === 'production'
    // ? 'https://us-central1-inlo-10884.cloudfunctions.net/label/indisearch'
    // : 'http://localhost:8010/inlo-10884/us-central1/label/indisearch';
    const { cancellablePromise } = useCancellablePromise();
    const url = "http://localhost:8010/inlo-10884/us-central1/esearch";

    // const doGet = (body) => {
    //     console.log("updating body for useEsearch");
    //     setBody(body);
    // };

    const esearch = async () => {


        setLoading(true);

            const token = await firebase.auth.currentUser.getIdToken();

            const headers = {
                Authorization: 'Bearer ' + token,
            };

            const wrappedPromise = cancellablePromise(axios.post(`${url}/ppred/_search`, body, { headers: headers }));

            // await sleep(3000);

            wrappedPromise.then(result => {
                // console.log(result.data.hits);
                if (result) {
                    setMetadata((result.data && result.data.hits) || {});
                    setLoading(false);
                }
            }).catch(err => {
                if (!err.isCanceled) { setError(err.error);
                    message.error(err.message, 2);
                    console.log("error searching:", err);
                    setLoading(false);
                 }
                    else {
                        console.log("search is cancelled");
                    }
                });
 
                // const wrappedPromise = makeCancelable(axios.post(url, {
                //     labeltype,
                //     size: this.props.sz,
                //     dones: Object.keys(this.state.labels),
                //     offset
                // }, { headers: headers }));
                // this.appendPendingPromise(wrappedPromise);
                // return wrappedPromise.promise.then(result => {
                //     if (result.data) {
                //         this.setState({ [name]: result.data || [] })
                //     }
                //     this.removePendingPromise(wrappedPromise);
                // })
        
        
            
        }


        // if (token && body !== {}) {
        //     // console.log("body is:", body);

        //     const headers = {
        //         Authorization: 'Bearer ' + token,
        //     };

        //     setLoading(true);

        //     // await sleep(3000);

        //     const wrappedPromise = cancellablePromise(axios.post(`${url}/ppred/_search`, body, { headers: headers }));

        //     wrappedPromise.then(result => {
        //         console.log(result.data.hits);
        //         if (result) {
        //             setData((result.data && result.data.hits) || {});
        //         }
        //     })
        //         .catch(error => {
        //             setError(error);
        //             if (error.response) {
        //                 if (error.response.status === 433) {
        //                     message.warn('please refresh page', 2);
        //                 } else {
        //                     console.log('search error:', error);
        //                     message.error(error.message, 2);
        //                 }
        //             }
        //         });

        //     setLoading(false);
        // }
    

    useEffect(() => {
        if (body && Object.keys(body).length > 0)
        {
        esearch();
        }
    }, [body]);

    return { metadata, loading, error, setBody};

}


export const useUpdateDb = (firebase, refname, uid, childname = '') => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const ref = childname === '' ? firebase[refname](uid) : firebase[refname](uid).child(childname);

    const postData = async () => {

        if (Object.keys(data).length > 0) {
            setError(null);
            setLoading(true);


            ref.update(data)
                .catch(error => {
                    setError(error);
                    console.log('update error', error);
                    message.error(error.message, 2);
                });

            setLoading(false);
        }
    }

    useEffect(() => {
        postData();
    }, [data]);

    return { loading, error, setData };
}


export const useRemoveDb = (firebase, refname, uid, childname = '') => {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [keyname, setKeyname] = useState(null);

    const ref = childname === '' ? firebase[refname](uid) : firebase[refname](uid).child(childname);

    const deleteData = async () => {

        if (keyname) {
            setError(null);
            setLoading(true);


            ref.child(keyname).remove()
                .catch(error => {
                    setError(error);
                    console.log('update error', error);
                    message.error(error.message, 2);
                });

            setLoading(false);
        }
    }

    useEffect(() => {
        deleteData();
    }, [keyname]);

    return { loading, error, setKeyname };
}


export const useQuizStatus = () => {


    const [quizstatus, setQuizstatus] = useState(null);
    const [error, setError] = useState(null);
    const [uid, setUid] = useState(null);

    const getQuizStatus = async () => {
        // firebase.auth().currentUser.getIdToken(true)
        if (uid) {
            this.props.firebase.db.ref(`users/${uid}/quiz`)
            .once("value")
            .then(snapshot => setQuizstatus(snapshot.val()))
            .catch(error => {
                setError(error);
                console.log('update error', error);
                message.error(error.message, 2);
            });
        }
    }

    useEffect(() => {
        getQuizStatus();
    }, [uid]);

    return { quizstatus, error, setUid };

}