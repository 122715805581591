export const cues = [12, 100, 1000];

export const colormappingtf = {[true]: '#00cc99', [false]:'#ff7f50'};

export const length_variations = {'v0':['leg/thigh split', 'mesh overlay', 'varying length']};

export const mini_lengths = {'v0': ['closer to the top of thigh', 'around mid-thigh', 'just above kneecap', 'at kneecap', 'below kneecap']};

export const midi_lengths = ['above knee', 'at knee', 'below knee but above mid-calf', 'mid-calf', 'below mid-calf', 'of varying lengths or has a split'];

export const maxi_lengths = ['mid-calf or above', 'lower than mid-calf but above ankle', 'at ankle', 'at ground if not on heels', 'at ground with heels', 'drape on ground even with heels', 'of varying lengths or has a split']


export const attributes = {
    'lenextra-split': 'the split skirt',
    'pattern-floral': 'the floral print',
    'pattern-all prints': 'the overall prints',
    'deco-cut out': 'the cutout design',
    'general-keyhole back': 'the keyhole back',
    'general-straps': 'the straps design',
}


export const improvements = ['Save time shopping','Save money shopping','More style choices',
'Fewer style choices, but better','Better sizing','Better style recommendations','More cutting edge or more "in" choices']

export const itemtypes = ['hat', 'hoodie', 'shirt', 'pants', 'jeans', 'shorts', 'skirt', 
'dress',  'jumpsuit', 'sweater', 'suit', 'jacket', 'coat', 'shoes', 'swimwear', 'sunglasses', 'bag'];

// const itemTypesFull = ['hat', 'lingerie', 'top e.g. shirt, hoodie', 'bottom: e.g pants, shorts, skirt', 'dress/jumpsuit',  
// 'sweater', 'suit', 'jacket', 'coat', 'bag', 'shoes', 'swimwear', 'accessaries e.g. sunglasses, socks']


export const purposes = [
    'Casual Wear', 'Dress-up Occasions', 'Dating', 'Work', 'Socials', 'Night Out', 'Just For My Own Enjoyment', 'Costume/Acting'
 ];
 


export const stylewords = [
   'edgy','conservative', 'cute','nice','ugly','tame','bright','magnificent','sexy','plain','boring','elegant','stylish','regal',
   'trashy','modern','simple','clean','frilly','somber','joyless','desperate','revealing','moderate','hint','shiny','glistening',
   'exotic','preppy','thoughtless','cheap','low-quality','trying-too-hard','loud','noisy','garish','gaudy','tasteless','matronly',
   'showy', 'vulgar', 'pretentious', 'rebellious', 'avant garde', 'original', 'quiet', 'luxurious','delux', 'dull', 'cutesy', 'pastel',
   'monochrome', 'bizzare', 'outlandish', 'sleek', 'sloppy', 'intricate'
];


export const attris = 
[
    { 'savelabel': 'bodycon', 'searchlabel': 'too tight bodycon body conscious fit skinny', 'displaylabel': 'tight/bodycon fit', 'aspect': 'fit' },
    { 'savelabel': 'loose', 'searchlabel': 'too loose fit', 'displaylabel': 'loose fit', 'aspect': 'fit' },
    { 'savelabel': 'regular', 'searchlabel': 'regular fit', 'displaylabel': 'regular fit', 'aspect': 'fit' },
    { 'savelabel': 'animal', 'searchlabel': 'animal print pattern', 'displaylabel': 'animal print', 'aspect': 'pattern' },
    { 'savelabel': 'parsley', 'searchlabel': 'parsley print pattern', 'displaylabel': 'parsley print', 'aspect': 'pattern' },
    { 'savelabel': 'sequin', 'searchlabel': 'sequined', 'displaylabel': 'sequin', 'aspect': 'decoration' },
    { 'savelabel': 'embroidery', 'searchlabel': 'embroidery embroidered all-over heavy all', 'displaylabel': 'embroidery', 'aspect': 'decoration' },
    { 'savelabel': 'frill', 'searchlabel': 'frilly', 'displaylabel': 'frill', 'aspect': 'decoration' },
    { 'savelabel': 'super-maxi', 'searchlabel': 'maxi too super long length skirt past ankle', 'displaylabel': 'super-maxi length', 'aspect': 'length' },
    { 'savelabel': 'super-mini', 'searchlabel': 'mini too super short length skirt above mid-thigh', 'displaylabel': 'super-mini length', 'aspect': 'length' },
    { 'savelabel': 'maxi', 'searchlabel': 'maxi long length skirt', 'displaylabel': 'maxi length', 'aspect': 'length' },
    { 'savelabel': 'mini', 'searchlabel': 'mini short length skirt', 'displaylabel': 'mini length', 'aspect': 'length' },
    { 'savelabel': 'lowneck', 'searchlabel': 'low-cut lowcut deep v plunge neckline super low', 'displaylabel': 'plunge neckline', 'aspect': 'neck' },
    { 'savelabel': 'maternity', 'searchlabel': 'maternity pregnant', 'displaylabel': 'maternity', 'aspect': 'collection' },
    { 'savelabel': 'plus', 'searchlabel': 'plus-size plus size large', 'displaylabel': 'plus-size', 'aspect': 'collection' },
    { 'savelabel': 'petite', 'searchlabel': 'petite-size petite size small short', 'displaylabel': 'petite-size', 'aspect': 'collection' },
    { 'savelabel': 'tall', 'searchlabel': 'tall-size tall size long skinny', 'displaylabel': 'tall-size', 'aspect': 'collection' },
    // { 'savelabel': 'something', 'searchlabel': 'zdx', 'displaylabel': 'innocent looking', 'aspect': 'length' },
];


export const colormapping = {
    'length': 'magenta',
    'fit': 'cyan',
    'pattern': 'gold',
    'color': '#c0c0c0',
    'decoration': 'purple',
    'neck': 'volcano',
    'material': 'green',
    'collection': 'blue',
};


export const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos(Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea - Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "North Korea",
    "South Korea",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Nagorno - Karabakh",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Turkish Republic of Northern Cyprus",
    "Northern Mariana",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn Islands",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Barthelemy",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "Somaliland",
    "South Africa",
    "South Ossetia",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor - Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Transnistria Pridnestrovie",
    "Trinidad and Tobago",
    "Tristan da Cunha",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (UAE)",
    "United Kingdom (UK)",
    "United States (USA)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "British Virgin Islands",
    "Isle of Man",
    "US Virgin Islands",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
];


export const usprovinces = [
    "Alabama, AL",
    "Alaska, AK",
    "Arizona, AZ",
    "Arkansas, AR",
    "California, CA",
    "Colorado, CO",
    "Connecticut, CT",
    "Delaware, DE",
    "Florida, FL",
    "Georgia, GA",
    "Hawaii, HI",
    "Idaho, ID",
    "Illinois, IL",
    "Indiana, IN",
    "Iowa, IA",
    "Kansas, KS",
    "Kentucky, KY",
    "Louisiana, LA",
    "Maine, ME",
    "Maryland, MD",
    "Massachusetts, MA",
    "Michigan, MI",
    "Minnesota, MN",
    "Mississippi, MS",
    "Missouri, MO",
    "Montana, MT",
    "Nebraska, NE",
    "Nevada, NV",
    "New Hampshire, NH",
    "New Jersey, NJ",
    "New Mexico, NM",
    "New York, NY",
    "North Carolina, NC",
    "North Dakota, ND",
    "Ohio, OH",
    "Oklahoma, OK",
    "Oregon, OR",
    "Pennsylvania, PA",
    "Rhode Island, RI",
    "South Carolina, SC",
    "South Dakota, SD",
    "Tennessee, TN",
    "Texas, TX",
    "Utah, UT",
    "Vermont, VT",
    "Virginia, VA",
    "Washington, WA",
    "West Virginia, WV",
    "Wisconsin, WI",
    "Wyoming, WY",
];

export const caprovinces = [
    "Alberta, AB",
"British Columbia, BC",
"Manitoba, MB",
"New Brunswick, NB",
"Newfoundland and Labrador, NL",
"Northwest Territories, NT",
"Nova Scotia, NS",
"Nunavut, NU",
"Ontario, ON",
"Prince Edward Island, PE",
"Quebec, QC",
"Saskatchewan, SK",
"Yukon, YT",
];