import React, { Component, useState, useEffect, useCallback, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import bodybuilder from 'bodybuilder';

import {
    Form, Input, Icon, Button, Select, Row, Col, Card, Tooltip, InputNumber, AutoComplete, Drawer, Skeleton, Empty, Carousel, Modal
} from 'antd';



import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import { purposes, itemtypes, colormapping, stylewords, attributes } from '../../constants/autocomplete';
import { useModal, useFormSubmit, useEsearch, useFirebase } from '../Hooks';
import {ImgCarousel} from './description';



const { Option } = Select;
const { Meta } = Card;

const purposeChildren = purposes.map(i => <Option key={i}>{i}</Option>);

const aspects = ['general', 'deco', 'pattern', 'lenextra', 'neck'];



export const FormDrawer = (props) => {

    let carousel = useRef();

    if (Object.keys(props.item).length >0 ) {

    console.log(props.item);
    const attris = (props.item && [].concat(...aspects.map(j => (props.item[j] && props.item[j].map(i => `${j}-${i}`)) || []))) || [];
    const attrisLabels = (attris && attris.map(i => attributes[i])) || [];

    console.log("attris:", attris);
    console.log("attrisLabels:", attrisLabels);

    const attriChildren = (attrisLabels && attrisLabels.map(i => i && <Option key={i}>{i}</Option>)) || null;


    // const next = () => {
    //     carousel.next();
    //   }

    // const previous = () => {
    //     carousel.prev();
    //   }

    //   const slides = (props.item.img_links && props.item.img_links.map(i =>
    //     <div >
    //     <img alt="Chiccue" src={i} className="clickable" onClick={next} style={{maxWidth:"100%"}}/>
    // </div>)) || <div></div>;

    // const carousel_props = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //   };


    return (

        <Modal
            visible={props.visible}
            title="Notes"
            placement="left"
            closable={true}
            maskClosable={false}
            width={"40%"}
            style={{ maxWidth: 400 }}
            footer={null}
            // onClose={props.handleCancel}
            onCancel={props.handleCancel}
        >
        <Row type="flex" justify="center" gutter={2} style={{ marginTop: 40}}>
        <Col span={2}>
        {/* <Icon type="caret-left" onClick={previous} style={{lineHeight: "300px", fontSize: 30}}/> */}
        </Col>
        <Col span={18}>
            <ImgCarousel source={props.item}/>
            </Col>
            <Col span={2}>
            <div className='button-wrapper'>
            {/* <Icon type="caret-right" onClick={next} style={{lineHeight: "300px", fontSize: 30}}/> */}
            </div>
            </Col>
            </Row>
            <WrappedInnerForm {...props} attriChildren={attriChildren}/>

        </Modal>

    );
    }else{
        return <div></div>
    }
}


const InnerForm = (props) => {

    const { getFieldDecorator } = props.form;
    const { formdata, loading, setSubmit, submit } = useFormSubmit(props.firebase, 'reviews', props.uid, props.form, `/notes/${props.item.pid}`);
    const stylepref = useFirebase(props.firebase, 'userprefs', props.authUser.uid);
    console.log(stylepref.data);


    const stylelikeChildren = (Object.keys(stylepref.data).length && 
    Object.entries(stylepref.data)
    .filter(i => i[1]===true)
    .map(i => <Option key={i}>{i}</Option>)) || 
    stylewords.map(i => <Option key={i}>{i}</Option>);

    const styledislikeChildren = (Object.keys(stylepref.data).length && 
    Object.entries(stylepref.data)
    .filter(i => i[1]===false)
    .map(i => <Option key={i}>{i}</Option>)) || 
    stylewords.map(i => <Option key={i}>{i}</Option>);


    const onSubmit = async (e) => {
        e.preventDefault();
        setSubmit(submit + 1);
        props.onSubmit();
    }

    useEffect(() => {
        props.onFormDataChange(formdata);
    }, [formdata]);


    const LikeForm = (props) => <Form onSubmit={onSubmit}>

    <span className="ant-form-text">I would wear it for these uses or purposes: </span>
    <div style={{ minHeight: 60, marginTop: 20 }}>

        <Row type="flex" gutter={6}>
            <Col span={20}>
                <Form.Item required={false}
                >
                    {getFieldDecorator('use', {
                        validateTrigger: ['onBlur'],
                        rules: [{ required: true, message: 'Please choose purposes/uses' }],
                    })(
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Select use"
                        // defaultValue={['a10', 'c12']}
                        // onChange={handleMultiSelectChange}
                        >
                            {purposeChildren}
                        </Select>
                    )}
                </Form.Item>
            </Col>
        </Row>
    </div>


    <span className="ant-form-text">Is there anything specific that you like about this item?</span>

    <div style={{ minHeight: 100, marginTop: 20 }}>

        <Row type="flex" gutter={12}>
        <Col span={20}>
            <Form.Item required={false}
            >
                {getFieldDecorator('aspect', {
                })(
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Select or add aspect"
                    // defaultValue={['a10', 'c12']}
                    // onChange={handleMultiSelectChange}
                    >
                        {props.attriChildren}
                    </Select>
                )}
            </Form.Item>
            </Col>
        </Row>

        <span className="ant-form-text">What's the overall impression of the style in your words?</span>
        <Row type="flex">

        <Col span={20}>
            <Form.Item
            >
                {getFieldDecorator('userpref', {
                })(
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Select or add words/phrases"
                    // defaultValue={['a10', 'c12']}
                    // onChange={handleMultiSelectChange}
                    >
                        {stylelikeChildren}
                    </Select>
                )}
            </Form.Item>
            </Col>
        </Row>
    </div>
    <Form.Item>
        <Button style={{ width: "33%", float: "right" }} type="primary" htmlType="submit" loading={loading}>
            OK
    </Button>
    </Form.Item>

</Form >


const DislikeForm = (props) => <Form onSubmit={onSubmit}>


    <span className="ant-form-text">Is there anything specific that you dislike about this item?</span>

    <div style={{ minHeight: 100, marginTop: 20 }}>

        <Row type="flex" gutter={12}>
        <Col span={20}>
            <Form.Item required={false}
            >
                {getFieldDecorator('aspect', {
                })(
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Select or add aspect"
                    // defaultValue={['a10', 'c12']}
                    // onChange={handleMultiSelectChange}
                    >
                        {props.attriChildren}
                    </Select>
                )}
            </Form.Item>
            </Col>
        </Row>

        <span className="ant-form-text">What's the overall impression of the style in your words?</span>
        <Row type="flex">

        <Col span={20}>
            <Form.Item
            >
                {getFieldDecorator('userpref', {
                })(
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Select or add words/phrases"
                    // defaultValue={['a10', 'c12']}
                    // onChange={handleMultiSelectChange}
                    >
                        {styledislikeChildren}
                    </Select>
                )}
            </Form.Item>
            </Col>
        </Row>
    </div>
    <Form.Item>
        <Button style={{ width: "33%" }} type="primary" htmlType="submit" loading={loading}>
            OK
    </Button>
    </Form.Item>

</Form >


const OwnForm = (props) => <Form onSubmit={onSubmit}>

    <span className="ant-form-text">I would wear it for these uses or purposes: </span>
    <div style={{ minHeight: 60, marginTop: 20 }}>

        <Row type="flex" gutter={6}>
            <Col span={20}>
                <Form.Item required={false}
                >
                    {getFieldDecorator('use', {
                        validateTrigger: ['onBlur'],
                        rules: [{ required: true, message: 'Please choose purposes/uses' }],
                    })(
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Select use"
                        // defaultValue={['a10', 'c12']}
                        // onChange={handleMultiSelectChange}
                        >
                            {purposeChildren}
                        </Select>
                    )}
                </Form.Item>
            </Col>
        </Row>
    </div>


    <span className="ant-form-text">Is there anything specific that you like about this item?</span>

    <div style={{ minHeight: 100, marginTop: 20 }}>

        <Row type="flex" gutter={12}>
        <Col span={20}>
            <Form.Item required={false}
            >
                {getFieldDecorator('aspect', {
                })(
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Select or add aspect"
                    // defaultValue={['a10', 'c12']}
                    // onChange={handleMultiSelectChange}
                    >
                        {props.attriChildren}
                    </Select>
                )}
            </Form.Item>
            </Col>
        </Row>

        <span className="ant-form-text">What's the overall impression of the style in your words?</span>
        <Row type="flex">

        <Col span={20}>
            <Form.Item
            >
                {getFieldDecorator('userpref', {
                })(
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Select or add words/phrases"
                    // defaultValue={['a10', 'c12']}
                    // onChange={handleMultiSelectChange}
                    >
                        {stylelikeChildren}
                    </Select>
                )}
            </Form.Item>
            </Col>
        </Row>
    </div>
    <Form.Item>
        <Button style={{ width: "33%" }} type="primary" htmlType="submit" loading={loading}>
            OK
    </Button>
    </Form.Item>

</Form >



    switch (props.type) {
        case 'like':
            return <LikeForm {...props}/>
        case 'dislike':
        return <DislikeForm {...props}/>    
        case 'own':
        return <OwnForm {...props}/>
        case 'view':
        return <div></div>    
        default:
            console.log("not a valid click value");
    }


}

const WrappedInnerForm = Form.create({ name: 'review_items' })(InnerForm);
// const WrappedLikeForm = Form.create({ name: 'review_items' })(LikeForm);
// const WrappedLikeForm = Form.create({ name: 'review_items' })(LikeForm);



export default FormDrawer;