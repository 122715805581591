import React, { Component, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import axios from 'axios';

import {
    Icon, Button, Row, Col, Card, Spin, Skeleton, Empty, Checkbox, message, BackTop, Radio, Carousel, Progress
} from 'antd';

import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';
import * as ROUTES from '../../constants/routes';
import { purposes, itemtypes, mini_lengths, midi_lengths, maxi_lengths, length_variations } from '../../constants/autocomplete';
import { makeCancelable } from '../Designs';
import { required_num_labels } from '../../constants/thresh';

const { Meta } = Card;

const radio_version = 'v0';
const check_version = 'v0';



const url = process.env.NODE_ENV === 'production'
    ? 'https://us-central1-inlo-10884.cloudfunctions.net/label/search'
    : 'http://localhost:8010/inlo-10884/us-central1/label/search';

const reurl = process.env.NODE_ENV === 'production'
    ? 'https://us-central1-inlo-10884.cloudfunctions.net/label/review'
    : 'http://localhost:8010/inlo-10884/us-central1/label/review';


const labeltype = 'mini';


const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

const mini_radios = mini_lengths[radio_version].map((e, index) => <Radio key={index} style={radioStyle} value={index}>{e}</Radio>);

const plainOptions = length_variations[check_version].map((i, index) => ({ 'label': i, 'value': index }));


export const ImgCarousel = (props) => {

    let carousel = useRef();

    const next = () => {
        carousel.next();
    }

    const slides = (props.source.img_links && props.source.img_links.map((i, index) =>
        <div key={index}>
            <img alt="example" src={i} className="clickable" onClick={next} style={{ maxWidth: "100%" }} />
        </div>)) || <div></div>;


    const carousel_props = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Carousel ref={node => (carousel = node)} {...carousel_props} className="imagereview">
            {slides}
        </Carousel>);
}



const Resultcards = (props) => (props.data.length > 0 &&
    props.data.map(i =>
        <Col className="gutter-row" xs={20} sm={11} md={8} lg={6} key={i._source.pid}>
            <Skeleton loading={props.loading} active>
                <Card style={{ marginTop: 40, minHeight: 600 }}
                    cover={<ImgCarousel source={i._source} />}
                    actions={[<Icon type="heart" theme={props.like.includes(i._source.pid) ? "filled" : null} onClick={() => props.onClick('like', i._source.pid)} />,
                    <Icon type="dislike" theme={props.dislike.includes(i._source.pid) ? "filled" : null} onClick={() => props.onClick('dislike', i._source.pid)} />,
                    <Icon type="database" theme={Object.keys(props.usero).includes(i._source.pid) ? "filled" : null} onClick={() => props.onClick('own', i._source.pid)} />]}
                >
                    <Meta
                        // title={"Length?"}
                        description={<Checkbox.Group options={plainOptions} onChange={(value) => props.onCheckChange(value, i._source.pid)} />}
                    />
                    <Row>
                        <div style={{ marginTop: 20, float: "bottom" }}>
                            <Radio.Group onChange={(e) => props.onRadioClick(e, i._source.pid)}>
                                {mini_radios}
                            </Radio.Group>
                        </div>
                    </Row>

                </Card>
            </Skeleton>
        </Col>))
    || <Empty
        image="https://aworldoffantasies.files.wordpress.com/2012/07/narniawardrobe.jpg"
        description={
            <span>
                No Items
</span>
        }
    >
    </Empty>



class SearchResults extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            userpieces: {},
            userowns: {},
            like: [],
            dislike: [],
            labels: {},
            choices: {},
            modalTitle: '',
            error: null,
            loading: false,
            data: [],
            offset: 0
        };

    }

    pendingPromises = [];

    componentWillUnmount = () =>
        this.pendingPromises.map(p => p.cancel());

    appendPendingPromise = promise =>
        this.pendingPromises = [...this.pendingPromises, promise];

    removePendingPromise = promise =>
        this.pendingPromises = this.pendingPromises.filter(p => p !== promise);

    getData = (rootname, childpath = '') => {
        // const wrapped = makeCancelable(this.props.firebase[refname](this.props.authUser.uid).once("value"));
        const wrapped = makeCancelable(this.props.firebase.db.ref(`${rootname}/${this.props.authUser.uid}${childpath}`).once("value"));
        this.appendPendingPromise(wrapped);
        return wrapped.promise.then(snapshot => snapshot.val()).then(
            result => {
                if (result) {
                    this.setState({ [rootname]: result });
                }
            }).then(this.removePendingPromise(wrapped))
    }

    postData = (rootname, values, childpath = '') => {
        return this.props.firebase.db.ref(`${rootname}/${this.props.authUser.uid}${childpath}`)
            .update(values);
        // return this.props.firebase[refname](this.props.authUser.uid).child(childname)
        //     .update(values);
    }

    // getRootData = (refname) => {
    //     const wrapped = makeCancelable(this.props.firebase[refname]().once("value"));
    //     this.appendPendingPromise(wrapped);
    //     return wrapped.promise.then(snapshot => snapshot.val()).then(
    //         result => {
    //             if (result) {
    //                 this.setState({ [refname]: result });
    //             }
    //         }).then(this.removePendingPromise(wrapped))
    // }

    postRootData = (rootname, values, childpath = '') => {
        return this.props.firebase.db.ref(`${rootname}${childpath}`)
            .update(values);
        // return this.props.firebase[refname]().child(childname)
        //     .update(values);
    }

    handleRejection = (p) => {
        return p.catch(err => {
            if (!err.isCanceled) {
                console.log('error handling promise:', err);
                this.setState({ error: err.error });
                message.error(err.message, 2);
            }
            this.removePendingPromise(p);
        }
        );
    }


    initData = async () => {
        return Promise.all([this.getData('labels', `/${labeltype}/labels`), this.getData('userowns'), this.getData('userpieces')].map(this.handleRejection));
    }

    // componentDidMount = async () => {
    //     await this.setState({ loading: true });
    //     this.initData().then(() => this.setState({like: this.sift(true), dislike: this.sift(false), loading: false}));
    // }

    onCheck = (values, id) => {
        // console.log(id);
        // console.log('checked = ', values);
        this.setState((state, props) => ({
            choices: { ...state.choices, [id]: { ...state.choices[id], [`check_${check_version}`]: values } }
        }));
    }

    onRadioClick = (e, id) => {
        // console.log(e.target);
        // console.log(e.target.value);
        this.setState((state, props) => ({
            choices: { ...state.choices, [id]: { ...state.choices[id], [`radio_${radio_version}`]: e.target.value } }
        }));
    }

    onProceed = async () => {

        await this.setState({ loading: true });

        this.initData().then(() => {

            return this.search(0);

        }).then(() => this.setState({ like: this.sift(true), dislike: this.sift(false), loading: false, show: true }));

    }

    esearch = (offset, headers, name) => {
        const wrappedPromise = makeCancelable(axios.post(url, {
            labeltype,
            size: this.props.sz,
            dones: Object.keys(this.state.labels),
            offset
        }, { headers: headers }));
        this.appendPendingPromise(wrappedPromise);
        return wrappedPromise.promise.then(result => {
            if (result.data) {
                this.setState({ [name]: result.data || [] })
            }
            this.removePendingPromise(wrappedPromise);
        })
    }


    search = async (offset) => {

        const token = await this.props.firebase.auth.currentUser.getIdToken();
        // const token =  this.props.authUser.stsTokenManager.accessToken;
        const headers = {
            Authorization: 'Bearer ' + token,
        };
        return this.handleRejection(this.esearch(offset, headers, 'data'));
    }

    sift = (boo) => { return boo ? Object.keys(this.state.userpieces).filter((key) => this.state.userpieces[key]) : Object.keys(this.state.userpieces).filter((key) => !this.state.userpieces[key]) }


    onClick = (type, id) => {

        const { like, dislike, userowns: usero } = this.state;

        switch (type) {
            case 'like':
                if (like.includes(id)) {
                    this.setState({ like: like.filter(i => i !== id) })
                } else {
                    this.setState({ like: [...like, id], dislike: dislike.filter(i => i !== id) })
                }
                break;
            case 'dislike':
                if (dislike.includes(id)) {
                    this.setState({ dislike: dislike.filter(i => i !== id) })
                } else {
                    this.setState({ dislike: [...dislike, id], like: like.filter(i => i !== id) })
                }
                break;
            case 'own':
                if (Object.keys(usero).includes(id)) {
                    const {
                        [id]: somevalue, ...restofIds
                    } = usero;
                    this.setState({ usero: restofIds });
                } else {
                    this.setState({ usero: { ...usero, [id]: true } });
                }
                break;
            default:
                console.log("not a valid click value");
        }
    }

    onNext = async () => {
        await this.setState({ loading: true });
        const radiocount = Object.values(this.state.choices).map(i => i[`radio_${radio_version}`]).filter(el => el != null).length;
        const likes = (this.state.like.length && Object.assign(...this.state.like.map(i => ({ [i]: true })))) || {};
        const dislikes = (this.state.dislike.length && Object.assign(...this.state.dislike.map(i => ({ [i]: false })))) || {};

        // this is where I post true labels
        // Promise.all([this.postRootData('truelabels', this.state.choices, `/${labeltype}`),
        // this.postData('labels', this.state.choices, `/${labeltype}/labels`),
        // this.postData('userowns', this.state.userowns),
        // this.postData('userpieces', { ...likes, ...dislikes })]
        //     .map(this.handleRejection))
        //     .then(() => {
        //         this.setState({ like: [], dislike: [], choices: {} });
        //         this.search(this.state.offset + 1).then(() => {
        //             this.setState({ loading: false });
        //             window.scrollTo(0, 0);
        //         }
        //         );
        //         this.setState((state, props) => ({
        //             offset: state.offset + 1
        //         }));
        //     });

        Promise.all([
            this.reviewChoices(),
            this.postData('labels', this.state.choices, `/${labeltype}/labels`),
            this.postData('userowns', this.state.userowns),
            this.postData('userpieces', { ...likes, ...dislikes })]
            .map(this.handleRejection))
            .then(() => {
                if (radiocount < this.props.sz) {
                    message.error("please describe all items on this page", 2);
                    this.setState({ loading: false });
                } else {
                    this.setState({ like: [], dislike: [], choices: {} });
                    this.search(this.state.offset+1).then(() => {
                        this.setState({ loading: false });
                        window.scrollTo(0, 0);
                    });
                    this.setState((state, props) => ({
                        offset: state.offset + 1
                    }));
                }
            });


    }

    reviewChoices = () => {
        const wrappedPromise = makeCancelable(axios.post(reurl,
            { uid: this.props.authUser.uid, choices: this.state.choices, labeltype }
        ));
        this.appendPendingPromise(wrappedPromise);

        return wrappedPromise.promise.then(result => {
            console.log(result);
            this.removePendingPromise(wrappedPromise);
        })
    }

    onSave = () => {
        console.log("save");
        const radiocount = Object.values(this.state.choices).map(i => i[`radio_${radio_version}`]).filter(el => el != null).length;
        const likes = (this.state.like.length && Object.assign(...this.state.like.map(i => ({ [i]: true })))) || {};
        const dislikes = (this.state.dislike.length && Object.assign(...this.state.dislike.map(i => ({ [i]: false })))) || {};

        Promise.all([
            this.reviewChoices(),
            this.postData('labels', this.state.choices, `/${labeltype}/labels`),
            this.postData('userowns', this.state.userowns),
            this.postData('userpieces', { ...likes, ...dislikes })]
            .map(this.handleRejection))
            .then(() => {
                if (radiocount < this.props.sz) {
                    message.error("please describe all items on this page", 2);
                } else {
                    this.props.history.push(ROUTES.RECOMMEND);
                }
            });
    }

    onDone = async () => {
        await this.setState({ loading: true });
        const radiocount = Object.values(this.state.choices).map(i => i[`radio_${radio_version}`]).filter(el => el != null).length;
        const likes = (this.state.like.length && Object.assign(...this.state.like.map(i => ({ [i]: true })))) || {};
        const dislikes = (this.state.dislike.length && Object.assign(...this.state.dislike.map(i => ({ [i]: false })))) || {};

        Promise.all([
            this.reviewChoices(),
            this.postData('labels', this.state.choices, `/${labeltype}/labels`),
            this.postData('userowns', this.state.userowns),
            this.postData('userpieces', { ...likes, ...dislikes })]
            .map(this.handleRejection))
            .then(() => {
                if (radiocount < this.props.sz) {
                    message.error("please describe all items on this page", 2);
                    this.setState({ loading: false });
                } else {
                    message.success("congrats, you are all done!", 1);
                    this.props.history.push(ROUTES.RECOMMEND);
                }
            });


    }

    render() {

        const { userowns: usero, like, dislike, data, loading, labels, offset } = this.state;

        const prompt =
            <Button type="primary" onClick={this.onProceed} loading={loading}>Proceed</Button>;

        return ((this.props.firebase.auth.currentUser === undefined) ? <div style={{ paddingLeft: 24, paddingRight: 24, maxWidth: 600, margin: "auto" }}><Spin /></div> : (
            <div style={{ paddingLeft: 24, paddingRight: 24, maxWidth: 1200, margin: "auto" }} >
                {this.state.show ?
                    (<React.Fragment>
                        <div style={{ maxWidth: 800, margin: "auto", marginTop: 20 }} >

                            <p>
                                Multiple pictures are given for each item below. Click on the pictures to scroll through them.
                                For each item, make a decision on how long it is for the model in these pictures, if the model were to stand straight.
                                For example, is the hemline above or below the kneecap?  </p>

                            <p>Sometimes it's a little harder to decide how long the skirt is. This may be because the skirt has two layers, in particular a mesh overlay;
                                or it's because some part of the skirt is longer than other parts; or the skirt has a leg or thigh split. </p>

                            <p>In those cases, check the corresponding checkbox. Then select the length of the longer part of the skirt.</p>
                        </div>
                        <div style={{ maxWidth: 300, margin: "auto", marginTop: 20 }} >
                            <Progress percent={Math.round((Object.keys(labels).length + offset * this.props.sz) * 100 / required_num_labels)}
                                format={percent => `${percent}% done`} style={{ width: "100%" }} />
                        </div>
                        <Row type="flex" justify="center" gutter={16} style={{ marginTop: 20 }}>
                            <Resultcards data={data} loading={loading} like={like} dislike={dislike} usero={usero}
                                onClick={(type, id) => this.onClick(type, id)}
                                onRadioClick={(e, id) => this.onRadioClick(e, id)}
                                onCheckChange={(value, id) => this.onCheck(value, id)}
                            />
                        </Row>
                        <Row type="flex" style={{ marginTop: 40, marginBottom: 30 }}>

                            <Col span={8}>
                                <Button style={{ width: "100%" }} type="primary" onClick={this.onSave}>Save and Exit</Button>
                            </Col>
                            {/* <Col span={8}>
                            </Col> */}
                            <Col span={8} offset={7}>
                                {Object.keys(this.state.labels).length + (this.state.offset + 1) * this.props.sz < required_num_labels ?
                                    <Button style={{ width: "100%" }} type="primary" onClick={this.onNext} loading={this.state.loading}>Next</Button> :
                                    <Button style={{ width: "100%" }} type="primary" onClick={this.onDone} loading={this.state.loading}>Done</Button>
                                }
                            </Col>

                        </Row>
                    </React.Fragment>) :
                    <Row type="flex" justify="center">
                        <div style={{ maxWidth: 600, margin: "auto" }}>
                            {prompt}
                        </div>
                    </Row>}

            </div>)
        );

    }


}




const PageBase = (props) => {

    return (
        <React.Fragment>
            <Row type="flex" justify="center">
     
                    <h2>Describe Items</h2>
  
            </Row>
            <SearchResults
                authUser={props.authUser}
                {...props}
                sz={24} />
            <div>
                <BackTop />
            </div>
        </React.Fragment>
    )
}


const ExtendedPageBase = compose(
    withRouter,
    withFirebase,
)(PageBase);


const Description = (props) => {

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <ExtendedPageBase authUser={authUser} />
            )}
        </AuthUserContext.Consumer>

    );
}

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
)(Description);






