import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Menu, Icon} from 'antd';
import logo from '../../constants/chiccue-logo.png';

import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import SignInForm from '../SignIn';
import SignUpForm from '../SignUp';
import PasswordForgetForm from '../PasswordForget';
import { withFirebase } from '../Firebase';



const SubMenu = Menu.SubMenu;
// const SigninMenu = SignInForm(Menu.Item);
const modalList = ['signin', 'signup', 'pwforget'];

// Place react component in menubar; vanilla html would result in many console errors
const LogoImage = () => <img style={{ height: "50px", paddingLeft: 12 }} src={logo} alt="Logo" />;


class NavigationBase extends Component {

  constructor(props) {
    super(props);

    this.state = {
      current: 'login',
      // signinSpin: false,
      // signinVisible: (this.props.location && this.props.location.state && this.props.location.state.visible) || false,
      signinVisible: false,
      signupVisible: false,
      pwforgetVisible: false,
    }

  }

  handleClick = (e) => {
    // console.log('click ', e);
    this.setState({
      current: e.key,
    });
  }



  showModal = (key) => {
    const obj = Object.assign(...modalList.map(i => ({ [`${i}Visible`]: i === key })));
    this.setState(obj);
  }



  cancelModal = (key) => {
    const stateName = `${key}Visible`;

    this.setState({
      [stateName]: false,
    });

  }




  render() {

    const { signinVisible, signupVisible, pwforgetVisible } = this.state;



    return (
      <React.Fragment>
        <SignUpForm visible={signupVisible} handleCancel={() => this.cancelModal("signup")} />
        <SignInForm visible={signinVisible} handleCancel={() => this.cancelModal("signin")} handleSignup={() => this.showModal("signup")} handlePwforget={() => this.showModal("pwforget")} />
        <PasswordForgetForm visible={pwforgetVisible} handleCancel={() => this.cancelModal("pwforget")} />
        <div style={{ maxWidth: 600, margin: "auto", marginTop: 20 }} >
        <AuthUserContext.Consumer>


          {authUser =>
            authUser ?
              <Menu
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
                mode="horizontal"
              >
                <LogoImage />
                <SubMenu title={<span className="submenu-title-wrapper"><Icon type="appstore" />My Items</span>}>
                  <Menu.Item key="recommend">
                    <Link to={ROUTES.RECOMMEND}><Icon type="bulb" />Recommendations</Link>
                  </Menu.Item>
                  {/* <Menu.Item key="recommend0">
                    <Link to={ROUTES.REVIEW_ITEMS0}><Icon type="question" />Rev_I0</Link>
                  </Menu.Item> */}
                  <Menu.Item key="likes">
                  <Link to={ROUTES.LIKES}><Icon type="heart" />Likes</Link>
                  </Menu.Item>
                  <Menu.Item key="description">
                  <Link to={ROUTES.DESCRIPTION}><Icon type="bars" />Descriptions</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu title={<span className="submenu-title-wrapper"><Icon type="user" />My Account</span>}>
                  <Menu.Item key="account">
                    <Link to={ROUTES.PROFILE}><Icon type="dashboard" />My Profile</Link>
                  </Menu.Item>
                  <Menu.Item key="settings">
                    <Link to={ROUTES.SETTINGS}><Icon type="setting" />Settings</Link>
                  </Menu.Item>
                  <Menu.Item key="quiz">
                    <Link to={ROUTES.QUIZ}><Icon type="schedule" />Quiz</Link>
                  </Menu.Item>
                  <Menu.Item key="extend">
                    <Link to={ROUTES.EXTEND}><Icon type="heart" theme="twoTone" twoToneColor="#eb2f96" />Extend My Membership</Link>
                  </Menu.Item>
                  <Menu.Item key="logout" onClick={this.props.firebase.doSignOut}>
                    <Icon type="logout" />Log Out
                </Menu.Item>
                </SubMenu>
                <Menu.Item key="notifications">
                <Icon type="bell" />
              </Menu.Item>

              </Menu>

              :

              <Menu
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
                mode="horizontal"
              >
                <LogoImage />
                <Menu.Item key="login" onClick={() => this.showModal("signin")}>
                  <Icon type="login" />Log in
              </Menu.Item>
              </Menu>
          }

        </AuthUserContext.Consumer>
        </div>
      </React.Fragment>
    );

  }

}


const Navigation = compose(
  withRouter,
  withFirebase,
)(NavigationBase);

export default Navigation;