import React, { Component } from 'react';
import {
    Form, Icon, Button, Tooltip, Select, Row, Col, Tag, Card, Avatar, Modal, message, Skeleton
} from 'antd';

import { stylewords, colormappingtf} from '../../constants/autocomplete';
import { makeCancelable } from '../Designs';

const Option = Select.Option;
const { Meta } = Card;

const mintagsrequired = 6;

const maxlabellength = 25;



function handleChange(value) {
    console.log(`selected ${value}`);
}


const WordsModal = (props) =>
        <Modal
        key={props.modalTitle}
        title={<span>Styles you {props.modalTitle}&nbsp;<Tooltip title="Any word or phrase works, but specific ones are more helpful. 
        For example, &quot;edgy&quot; is more specific than &quot;nice&quot;, because with just &quot;nice&quot; 
        it's hard to guess what makes the style nice."><Icon type="question-circle-o" /></Tooltip></span>}
        visible={props.visible}
        maskClosable={false}
        onOk={props.onClick}
        confirmLoading={props.confirmLoading}
        onCancel={props.handleCancel}
        bodyStyle={{height: 200}}
        okText={"Add"}
      >
      <p>Input words or phrases. Add as many as you like. Don't be limited by the choices here:</p>
        <Select
                          value={props.searchTerm}
                          mode="tags"
                          style={{ width: '95%' }}
                          placeholder="For example, &quot;edgy&quot;, &quot;understated&quot;, &quot;bizzare&quot; or &quot;plain&quot;"
                          onChange={handleChange}
                          hideAction={"click"}
                          onSelect={props.onSelect}
                          onDeselect={props.onDeselect}
                          // filterOption={(inputValue, option) => inputValue.length >= 2 ? display2list[option
                          // .props.children.toLowerCase()]['searchlabel'].includes(inputValue
                          // .toLowerCase()) : false}  this unfortunately doesn't cover the case of searching "too long" in "too super long"
                          // so it has to be searching for each string split by space. Only when all matches would the result be returned
                          filterOption={(inputValue, option) => {
                            const inputs = inputValue.toLowerCase().split(' ');
                            return inputValue.length >= 3 ? inputs.every(subs => option.props.children.toLowerCase().indexOf(subs) >= 0) : false
                          }}
                          // dropdownStyle={{maxHeight:100}}
                      >
                          {props.selectChildren}
                      </Select>

      </Modal>



class StylePrefQBase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: [],
            error: null,
            loading: true,
            termObject: {},
            visible: false,
            confirmLoading: false,
            modalTitle:'',
        };

    }

    pendingPromises = [];

    componentWillUnmount = () =>
        this.pendingPromises.map(p => p.cancel());

    appendPendingPromise = promise =>
        this.pendingPromises = [...this.pendingPromises, promise];

    removePendingPromise = promise =>
        this.pendingPromises = this.pendingPromises.filter(p => p !== promise);


    onNext = async (e) => {


        e.preventDefault();


        if (this.state.termObject && Object.keys(this.state.termObject).length >= mintagsrequired) {
            this.props.onSubmit();
        } else {
            message.error(`Need at least ${mintagsrequired} style tags in total`, 1.5);
        }


    }


    componentDidMount = () => {

        const ref = this.props.firebase[this.props.refname];
        const query = ref(this.props.uid);

        const wrappedPromise = makeCancelable(query.once("value"));
        this.appendPendingPromise(wrappedPromise);


        wrappedPromise.promise
            .then(snapshot => snapshot.val())
            .then(termObject => {
                if (termObject) {
                this.setState({ termObject, loading: false});
                this.removePendingPromise(wrappedPromise);
                }
            })
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error, loading: false });
                    this.removePendingPromise(wrappedPromise);
                }
            });
    

    }


    onDismissTerm = (savelabel) => {
        const { uid, refname } = this.props;

        const { termObject } = this.state;

        let newto = { ...termObject };  //clone object
        delete newto[savelabel];

        this.setState({ termObject: newto });
        this.removeFromDb(uid, savelabel, refname);

    }





    onSelect = (savelabel) => {
        // we should operate with savelabel, not displaylabel

        if (savelabel.length > maxlabellength) {
            message.error('Description too long', 1);
        } else {
            this.setState((state, props) => ({
                searchTerm: [...state.searchTerm, savelabel]
            }));
        }

    }


    onDeselect = (savelabel) => {
        // we should operate with savelabel, not displaylabel

        this.setState({
            searchTerm: this.state.searchTerm.filter(t => t !== savelabel),
        });
    }




    removeFromDb(uid, label, refname) {
        const ref = this.props.firebase[refname];
        return ref(uid).child(label)
            .remove()
            .catch(error => {
                this.setState({ error });
                console.log(error);
            });
    }


    onClick = (key) => {

        console.log(key);

        const prefvalue = key === 'like'? true : false;
        const { searchTerm, termObject } = this.state;
        const { uid, refname } = this.props;
        const ref = this.props.firebase[refname];
        let termsObj;
        const cleanterms = [...new Set(searchTerm.map(i => i.trim()))];

        if (searchTerm.length < 1) {
            return;
        }

        this.setState({
            confirmLoading: true,
          });


        termsObj = Object.assign(...cleanterms.map(i => ({ [i]: prefvalue })));

        this.setState({
            searchTerm: [],
            termObject: { ...termObject, ...termsObj },
            visible:false,
            confirmLoading: false,
        });

        ref(uid)
            .update(termsObj)
            .catch(error => {
                this.setState({ error });
                console.log(error);
            });


    }


    showModal = (title) => {
        this.setState({
            modalTitle: title,
          visible: true,
        });
      }


    
      handleCancel = () => {
        console.log('Clicked cancel button');
        this.setState({
          visible: false,
        });
      }



    render() {


        const {
            loading,
            searchTerm,
            termObject,
            visible,
            confirmLoading,
            modalTitle
        } = this.state;

        const selectChildren = stylewords.filter(w => !Object.keys(termObject).includes(w)).map(i => <Option key={i}>{i}</Option>)


        const TermTags = (props) => (props.termObject && Object.entries(props.termObject).filter(i => i[1]===props.tf).map(i => 
        <Tag key={i[0]} style={{marginTop:6}}
            closable afterClose={() => this.onDismissTerm(i[0])} color={colormappingtf[props.tf]}>{i[0]}
            </Tag>)) || <div></div>;


        const AddButton = (props) => <Button type="normal" onClick={props.onClick} style={{ marginTop: 20, width: '100%' }}>
            <Icon type="plus" />{props.name}
        </Button>


        const WordsCard = (props) => 
        <Card
            style={{ marginTop: 20 }}
        >
            <Skeleton loading={props.loading} active>
            <Meta
                title={props.title}
                description={props.description}
            />


            <div style={{ marginTop: 20, minHeight:300 }}>
                <TermTags termObject={props.termObject} tf={props.tf}/>
            </div>
            <div style={{ minHeight:50 }}>
            <AddButton name={props.name} onClick={props.onClick} style={{ marginTop: 20}}/>
            </div>
            </Skeleton>
        </Card>

        

        return (

            <div style={{maxWidth:800, margin: "auto"}}>
            <Card bordered={false}
            style={{ marginTop: 16 }}
          >
              <Meta
                title={<span>Describe the styles &nbsp;  
                    <Tooltip title="Compared with the previous question, this question asks for a more zoomed out view. Not about concrete design details, but the impressions the styles might make. ">
                <Icon type="question-circle-o" />
            </Tooltip></span>}
                description="What words or phrases would you use to describe the styles you like or dislike? Click the buttons to add them."
              />
            

                <Row type="flex" justify="center" gutter={6}>
                    <Col span={12}>
                        <WordsCard title="Like" name="Add" onClick={() => this.showModal('like')} termObject={termObject} tf={true} loading={loading}/>
                    </Col>
                    <Col span={12}>
                        <WordsCard title="Dislike" name="Add" onClick={() => this.showModal('dislike')} termObject={termObject} tf={false} loading={loading}/>
                    </Col>
                </Row>


                <div style={{ marginTop: 20, minHeight:60}}>
                    <Button type="primary" htmlType="submit" onClick={this.onNext} style={{ marginTop: 20, width: "60%", float: "right" }}>Next</Button>
                    </div>

            </Card>

            <WordsModal visible={visible} searchTerm={searchTerm} modalTitle={modalTitle} 
confirmLoading={confirmLoading} onClick={() => this.onClick(modalTitle)} handleCancel={this.handleCancel} 
onSelect={this.onSelect} onDeselect={this.onDeselect} selectChildren={selectChildren}/>

           </div>


        );
    }
}



const WrappedStylesFormBase = Form.create({ name: 'user_question' })(StylePrefQBase);


export default WrappedStylesFormBase;

