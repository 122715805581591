import React, { Component } from 'react';
import {
  Form, Icon, Input, Button, Modal, message
} from 'antd';

import { withFirebase } from '../Firebase';
import styles from '../../index.css';


const INITIAL_STATE = {
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, visible: this.props.visible };
  }


  handleCancel = () => {
    this.props.form.resetFields();
    console.log("reset fields");
    this.props.handleCancel();
};


  onSubmit = event => {

    const email = this.props.form.getFieldValue('email');


    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);

        this.props.firebase
        .doPasswordReset(email)
        .then(() => this.props.handleCancel())
        .catch(error => {
          this.setState({ error });
          console.log('pwreset error', error);
          message.error(error.message, 2);
        });
        
      } else {
        message.error('Please check your entries for error', 1);
        console.log("here's the validation error:", err);
      }
    });

  };



  render() {
    const { getFieldDecorator } = this.props.form;

    return (

<Modal
        visible={this.props.visible}
        title="Password Reset"
        onCancel={this.handleCancel}
        footer={null}
        closable={true}
        maskClosable={false}
        width={440}
      >
        <Form onSubmit={this.onSubmit} className={styles["login-form"]} >
          {/* style={{maxWidth: '300px',margin: 'auto'}} */}

          <Form.Item>
            {getFieldDecorator('email', {
              validateTrigger: ['onBlur'],
              rules: [
                {type: 'email', message: 'Not a valid email address'},
            { required: true, message: ' ' }],
            })(
              <Input allowClear name="email" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email address" onChange={this.onChange} />
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
              Reset My Password
            </Button>

          </Form.Item>
        </Form>
      </Modal>

    );
  }
}



const WrappedPasswordForgetFormBase = Form.create({ name: 'password_forget' })(PasswordForgetFormBase);

const PasswordForgetForm = withFirebase(WrappedPasswordForgetFormBase);

export default PasswordForgetForm;

