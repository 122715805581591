import React from 'react'
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import {
  message
} from 'antd';

const STRIPE_PUBLISHABLE = process.env.NODE_ENV === 'production'
  ? 'pk_live_8WsaPkF64FHPbkohVhNyGib0'
  : 'pk_test_ocbeBotqGygsEqMb1ik9Fbpe';

const PAYMENT_SERVER_URL = process.env.NODE_ENV === 'production'
  ? 'https://us-central1-inlo-10884.cloudfunctions.net/payment'
  : 'http://localhost:8010/inlo-10884/us-central1/payment';



const CURRENCY = 'USD';

const fromDollarToCent = amount => amount * 100;

const successPayment = data => {
  message.success('Payment Successful', 1.5);
};

const errorPayment = data => {
  message.error('Payment Error', 1.5);
};

const onToken = (amount, description) => token =>
  axios.post(PAYMENT_SERVER_URL,
    {
      description,
      source: token.id,
      currency: CURRENCY,
      amount: fromDollarToCent(amount)
    })
    .then(successPayment)
    .catch(errorPayment);

const Checkout = ({ name, description, amount }) =>
  <StripeCheckout
    name={name}
    description={description}
    amount={fromDollarToCent(amount)}
    token={onToken(amount, description)}
    currency={CURRENCY}
    stripeKey={STRIPE_PUBLISHABLE}
  />

export default Checkout;













// import React, {Component} from 'react';
// import {CardElement, injectStripe} from 'react-stripe-elements';

// class CheckoutForm extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {complete: false};
//     this.submit = this.submit.bind(this);
//   }

//   async submit(ev) {
//     let {token} = await this.props.stripe.createToken({name: "Name"});
//     let response = await fetch("/charge", {
//       method: "POST",
//       headers: {"Content-Type": "text/plain"},
//       body: token.id
//     });

//     if (response.ok) this.setState({complete: true});
//   }

//   render() {
//     if (this.state.complete) return <h1>Purchase Complete</h1>;

//     return (
//       <div className="checkout">
//         <p>Would you like to complete the purchase?</p>
//         <CardElement />
//         <button onClick={this.submit}>Send</button>
//       </div>
//     );
//   }
// }






// import React from 'react'
// import StripeCheckout from 'react-stripe-checkout';

// class CheckoutForm extends React.Component {
//     onToken = (token, addresses) => {
//         // TODO: Send the token information and any other
//         // relevant information to your payment process
//         // server, wait for the response, and update the UI
//         // accordingly. How this is done is up to you. Using
//         // XHR, fetch, or a GraphQL mutation is typical.
//     };

//     render() {
//         return (
//             <StripeCheckout
//                 stripeKey="pk_test_p0W537qAvKpPIz0BDTd4tYhn"
//                 token={this.onToken}
//                 amount="59.99"
//                 billingAddress
//                 description="Awesome Product"
//                 image="https://yourdomain.tld/images/logo.svg"
//                 locale="auto"
//                 name="Chic Cue"
//                 stripeKey="your_PUBLISHABLE_stripe_key"
//                 token={this.onToken}
//                 zipCode
//                 label="Pay with 💳"
//             />
//         )
//     }
// }

// export default CheckoutForm;









// <!-- Load Stripe.js on your website. -->
// <script src="https://js.stripe.com/v3"></script>

// <!-- Create a button that your customers click to complete their purchase. -->
// <button id="checkout-button">Pay</button>
// <div id="error-message"></div>

// <script>
//   var stripe = Stripe('pk_live_DapXZSD2feuFcMQ3OEtH0TeE', {
//     betas: ['checkout_beta_4']
//   });

//   var checkoutButton = document.getElementById('checkout-button');
//   checkoutButton.addEventListener('click', function () {
//     // When the customer clicks on the button, redirect
//     // them to Checkout.
//     stripe.redirectToCheckout({
//       items: [{plan: 'plan_EMKqrfO3RKKXIi', quantity: 1}],

//       // Note that it is not guaranteed your customers will be redirected to this
//       // URL *100%* of the time, it's possible that they could e.g. close the
//       // tab between form submission and the redirect.
//       successUrl: 'https://your-website.com/success',
//       cancelUrl: 'https://your-website.com/canceled',
//     })
//     .then(function (result) {
//       if (result.error) {
//         // If `redirectToCheckout` fails due to a browser or network
//         // error, display the localized error message to your customer.
//         var displayError = document.getElementById('error-message');
//         displayError.textContent = result.error.message;
//       }
//     });
//   });
// </script>