import React, { Component } from 'react';
import { Row, Col, Menu, Icon, Alert } from 'antd';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

// this is a class because it needs state
class Footer extends Component {

    state = {
        current: 'home',
    }

    handleClick = (e) => {
        console.log('click ', e);
        this.setState({
            current: e.key,
        });
    }

    componentDidMount() {

    }

    render() {
        return (
            //   <Menu
            //     onClick={this.handleClick}
            //     selectedKeys={[this.state.current]}
            //     mode="horizontal"
            //   >
            //     <Menu.Item key="about">
            //       <Link to="/about" rel="noopener noreferrer">About</Link>
            //     </Menu.Item>
            //     <Menu.Item key="terms">
            //       <Link to="/terms" rel="noopener noreferrer">Terms of Service</Link>
            //     </Menu.Item>
            //     <Menu.Item key="privacy">
            //       <Link to="/privacy" rel="noopener noreferrer">Privacy Policy</Link>
            //     </Menu.Item>
            //   </Menu>


            <div style={{ width: "100%", padddingLeft: 24, paddingRight: 24}}>
                <Row type="flex" justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
                    <span>© 2019, Chiccue</span>
                </Row>
            </div>

        )
    }
}

export default Footer