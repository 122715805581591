import React from 'react';
import { compose } from 'recompose';
import {
    // Form, Input, Tooltip, Icon, Row, Col, Checkbox, Button, Modal, Divider, message, Alert, Card, Avatar, Switch, Collapse
    Tooltip, Icon, Card, Avatar, Collapse
} from 'antd';

// import { withFirebase } from '../Firebase';

import {
    AuthUserContext,
    withAuthorization,
    withEmailVerification,
} from '../Session';
// import { Elements, StripeProvider } from 'react-stripe-elements';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Checkout from '../CheckOut';
import Social from '../Refer';
import { referral_num_needed } from '../../constants/thresh';

const Panel = Collapse.Panel;
const { Meta } = Card;


const introtext = `It gives us immense pleasure to know we are loved. \
Thank you! \
`;

const intro1 = `There are three ways to extend your membership, because, as you know, there isn't only one way to express oneself. These options are:`;

const intro2 = `The following three sections give more details to each of the three options.`;

const referralintro = <div>
    <p>Use any of the following buttons to share a link with your friends, on a social media site or through email.
    The link from the email button can also be copied to share in chats.</p>
    <p>Your referral code is embedded in these links, so when your friends sign up using them and get active on our site,
we know they are referred by you, and we count them up, and once that number reaches {referral_num_needed},
you gain one year of membership.</p>
    <p>Straightforward, right? </p>
    <p>It gets better than this. If you refer {referral_num_needed * 2} friends, you get two years of free membership; {referral_num_needed * 3} translates to three years etc.
    But if you refer {referral_num_needed * 5} friends, you don't ever need to pay for the Chiccue membership. EVER!* </p>
</div>

const referral_smallprint = <p> * Unless you reach the "free forever" tier, referrals are no basis for refunding membership fees, but will count forward to extend your membership. </p>

const descriptionintro = <div>
    <p>At Chiccue we care about recommending the items that are just right. Help us and the other users but taking a look at some items* and describe them. </p>
    <p>Your nuanced perspective will help others -- and eventually yourself -- to find items that are precisely what they'd like.</p>
    <p>We do wish to ensure good quality descriptions, however, so this would require good eyes. If you think you are up for the challenge,<Link to={ROUTES.DESCRIPTION}>&nbsp;proceed here</Link>. </p>
</div>

const description_smallprint = <p> * The number of items needed would vary depending on how easy it might be to describe these items. </p>

const paymentintro = <div>
    <p>You are right to conclude that time is your most valuable asset. That in fact is a main reason we built Chiccue -- it is to help you save time on things that are not interesting or enjoyable. </p>
    <p>Pay a membership fee to go straight to enjoying fashion. Better still, if you change your mind in three months*, we'll fully refund you. No question asked. </p>
</div>

const payment_smallprint = <p> * The change-of-mind refund can only work once per person, when a member is new to Chiccue: you can't change your mind every three months!</p>

const ExtendMembershipPage = () => (
    <AuthUserContext.Consumer>
        {authUser => (


            <div style={{ maxWidth: 1200, margin: "auto" }}>
                <Card bordered={false}
                    style={{ marginTop: 16 }}
                // actions={[<Icon type="caret-left" style={{fontSize: 30}} onClick={this.onPrev}/>, <Icon type="caret-right" style={{fontSize: 30}} onClick={this.onNext} />]}
                >

                    <Meta
                        avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                        title={
                            <span>
                                Extend My Membership
                            </span>
                        }
                        description={<span>"Let me count the ways"&nbsp; 
                            <Tooltip title="Maintain active membership status to fully enjoy Chiccue and obtain the items you love.">
                                <Icon type="question-circle-o" />
                            </Tooltip></span>}
                    />

                    <Collapse bordered={false} defaultActiveKey={['1']} style={{ opacity: 0.7 }}>
                        <Panel header="I love Chiccue! How can I remain a member?" key="1">
                            <p>{introtext}</p>
                            <p>{intro1}</p>
                            <ul>
                                <li key="refer">
                                    Refer us to your friends
    </li>
                                <li key="community">
                                    Help the Chiccue community
    </li>
                                <li key="pay">
                                    Pay straight
    </li>
                            </ul>
                            <p>{intro2}</p>
                        </Panel>
                        <Panel header="Social Referrals &nbsp; &quot;for those with many friends&quot;" key="2">
                            {referralintro}
                            <Social referer={authUser.uid} />
                            {referral_smallprint}

                        </Panel>
                        <Panel header="Community Improvement &nbsp; &quot;for those who make the world go round&quot;" key="3">
                            {descriptionintro}
                            {description_smallprint}
                        </Panel>
                        <Panel header="Pay for Membership &nbsp; &quot;for those whose time is precious&quot;" key="4">
                            {paymentintro}
                            <div style={{marginBottom: 20}}>
                            <Checkout
                                name={'Chiccue.com'}
                                description={'One-year membership at Chiccue'}
                                amount={89.99}
                            />
                            </div>
                            {payment_smallprint}
                        </Panel>
                    </Collapse>

                </Card>
            </div>



        )}
    </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
)(ExtendMembershipPage);


