import React, { Component, useState, useEffect, useCallback, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import bodybuilder from 'bodybuilder';

import {
    Form, Input, Icon, Button, Select, Row, Col, Card, Spin, InputNumber,
    AutoComplete, Skeleton, Empty, Switch, message, BackTop, Radio,
    Collapse, Tooltip, Tabs
} from 'antd';

import { withFirebase } from '../Firebase';
import { AuthUserContext, withEmailVerification, withAuthorization, withQuiz } from '../Session';
import { purposes, itemtypes, cues } from '../../constants/autocomplete';
import { useModal, useFormSubmit, useEsearch, useFirebase, useUpdateDb, useRemoveDb } from '../Hooks';

import NotesModal from './notes';
import styles from '../../index.css';
import { SearchResults } from './index';


const { Option } = Select;
const { Meta } = Card;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const TabPane = Tabs.TabPane;

const CatSelect = (props) => {


    const selectChildren = (props.selecttypes && props.selecttypes.map(i => i &&
        <Option key={i} value={i} >{i}
        </Option>)) || null;


    return (
        <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select a Type"
            optionFilterProp="children"
            onChange={props.onTypeChange}
            allowClear
            // onFocus={handleFocus}
            // onBlur={handleBlur}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {selectChildren}
        </Select>
    )
}



const PageBase = (props) => {

    const lastSearch = useFirebase(props.firebase, 'reviews', props.authUser.uid, `/lastsearch`);

    const notes = useModal();

    const { metadata, loading, error, setBody } = useEsearch(props.firebase);
    const [item, setItem] = useState({});
    const userp = useFirebase(props.firebase, 'userpieces', props.authUser.uid);
    const usero = useFirebase(props.firebase, 'userowns', props.authUser.uid);
    const [modaltype, setModaltype] = useState(null);
    const [notesmode, setNotesmode] = useState(true);
    const { visible, setVisible } = useModal(false);
    const [itemtype, setItemtype] = useState(null);

    const { setData } = useUpdateDb(props.firebase, 'userp', props.authUser.uid);
    const updateown = useUpdateDb(props.firebase, 'usero', props.authUser.uid);
    const { setKeyname } = useRemoveDb(props.firebase, 'userp', props.authUser.uid);
    const removeown = useRemoveDb(props.firebase, 'usero', props.authUser.uid);

    const [like, setLike] = useState([]);
    const [dislike, setDislike] = useState([]);
    const [own, setOwn] = useState([]);
    const [selecttypes, setSelecttypes] = useState([]);
    const [hits, setHits] = useState([]);


    const onClickSwitch = () => {
        notesmode ?
            message.success("Will not show popups for taking notes on these items.") :
            message.success("Turning on \"notes\" mode. There will be notes-taking popups.");
        setNotesmode(!notesmode);

    }

    const populateLast = async () => {

        if (lastSearch.data) {

            setItemtype(lastSearch.data.itemtype);

            const newbody = bodybuilder()
                .query('match', 'piece_type', lastSearch.data.itemtype)
                .filter('range', 'price', { gte: lastSearch.data.minprice, lte: lastSearch.data.maxprice })
                .size(12)
                .build();

            setBody(newbody);
        } else {
            message.warning("No search on record. Please specify new criteria.")
        }

        setVisible(false);


    }

    // const getmoreInfo = async () => {

    //     if (userp.data.length > 0) {

    //         const likes = userp.data.filter(i => i[1]===true).map(i => i[0]);
    //         console.log(likes);
    //         const newbody = bodybuilder()
    //             .query('match', 'piece_type', likes)
    //             .size(12)
    //             .build();

    //         setBody(newbody);
    //     } else {
    //         message.warning("No search on record. Please specify new criteria.")
    //     }
    //     setVisible(false);
    // }


    const onFormDataChange = async (formdata) => {

        setItemtype(formdata.itemtype);

        const newbody = bodybuilder()
            .query('match', 'piece_type', formdata.itemtype)
            .filter('range', 'price', { gte: formdata.minprice, lte: formdata.maxprice })
            .size(Math.min(formdata.cue, 24))
            .build();

        setBody(newbody);

    }

    const onNotesFormDataChange = (newformdata) => {
        console.log("form data has changed");
        console.log(newformdata);
        // setFormdata(newformdata);
        // const newbody = bodybuilder()
        //     .query('match', 'piece_type', newformdata.itemtype)
        //     .filter('range', 'price', { gte: newformdata.minprice, lte: newformdata.maxprice })
        //     .size(60)
        //     .build();

        // doGet(newbody);
    }

    const onShowItem = (type, source) => {
        setModaltype(type);
        setItem(source);
        notes.showModal();
    }

    const onNotesSubmit = () => {
        console.log("notes submitted");
        notes.setVisible(false);
    }

    const onClick = (type, source) => {

        switch (type) {
            case 'like':
                if (like.includes(source.pid)) {
                    setLike(like.filter(i => i !== source.pid));
                    setKeyname(source.pid);
                } else {
                    setLike([...like, source.pid]);
                    setDislike(dislike.filter(i => i !== source.pid));
                    setData({ [source.pid]: true });
                    if (notesmode) { onShowItem(type, source) };
                }
                break;
            case 'dislike':
                if (dislike.includes(source.pid)) {
                    setDislike(dislike.filter(i => i !== source.pid));
                    setKeyname(source.pid);
                } else {
                    setDislike([...dislike, source.pid]);
                    setLike(like.filter(i => i !== source.pid));
                    setData({ [source.pid]: false });
                    if (notesmode) { onShowItem(type, source) };
                }
                break;
            case 'own':
                if (own.includes(source.pid)) {
                    setOwn(own.filter(i => i !== source.pid));
                    removeown.setKeyname(source.pid);
                } else {
                    setOwn([...own, source.pid]);
                    updateown.setData({ [source.pid]: true });
                    if (notesmode) { onShowItem(type, source) };
                }
                break;
            default:
                console.log("not a valid click value");
        }
    }

    const onTypeChange = (value) => {
        setItemtype(value);

    }


    useEffect(() => {

        if (Object.keys(userp.data).length > 0) {

            const likes = Object.entries(userp.data).filter(i => i[1] === true).map(i => i[0]);
            const dislikes = Object.entries(userp.data).filter(i => i[1] === false).map(i => i[0]);

            if (likes.length > 0) {
                setLike(likes);
                console.log(likes);

                const newbody = bodybuilder()
                    .query('terms', 'pid', likes)
                    .size(12)
                    .build();

                setBody(newbody);
            }

            if (dislikes.length > 0) {
                setDislike(dislikes)
            }

        }

        if (Object.keys(usero.data).length > 0) {
            const owns = Object.entries(usero.data).map(i => i[0]);
            if (owns.length > 0) {
                setOwn(owns)
            }
        }

        return () => {
        };

    }, [usero.data, userp.data]);



    useEffect(() => {
        //this updates the dropdown list of item types to select from, if metadata changes
        if (metadata && metadata.hits && metadata.hits.length > 0) {
            console.log(metadata);
            const ptypes = Array.from(new Set(metadata.hits.map(i => i._source.piece_type)));
            setSelecttypes(ptypes);
            // hits={(metadata && metadata.hits) || []}
            // setSelecttypes(data);
        }

        return () => {
        };

    }, [metadata]);



    useEffect(() => {
        //this updates the hits (search results) to display, if either metadata or the type to display changes
        if (metadata && metadata.hits && metadata.hits.length > 0) {
            console.log(metadata);
            if (itemtype) {
                setHits(metadata.hits.filter(i => i._source.piece_type === itemtype));
            }
            else {
                setHits(metadata.hits);
            }
        }

        return () => {
        };

    }, [metadata, itemtype]);



    return (
        <React.Fragment>
            <div style={{ margin: "auto", paddingLeft: 24, paddingRight: 24, maxWidth: 1200 }}>
            <Row type="flex" justify="center" style={{marginTop: 20}}>
            <h2>Items I like</h2>
            </Row>
                <CatSelect
                    metadata={metadata}
                    selecttypes={selecttypes}
                    onTypeChange={onTypeChange}
                />
                <SearchResults
                    authUser={props.authUser}
                    data={metadata}
                    loading={loading}
                    onShowItem={onShowItem}
                    populateLast={populateLast}
                    notesmode={notesmode}
                    description={<span>
                        You've not recorded any items that you like yet
                    </span>}
                    like={like}
                    dislike={dislike}
                    own={own}
                    onClick={onClick}
                    hits={hits}
                    {...props}
                />
                <NotesModal
                    uid={props.authUser.uid}
                    visible={notes.visible}
                    handleCancel={notes.cancelModal}
                    onFormDataChange={onNotesFormDataChange}
                    item={item}
                    type={modaltype}
                    onSubmit={onNotesSubmit}
                    // onTokenChange={onTokenChange}
                    {...props} />

            </div>
            <div>
                <BackTop />
            </div>
        </React.Fragment>
    )
}





const ExtendedPageBase = compose(
    withRouter,
    withFirebase,
)(PageBase);


const Likes = (props) => {
    // const {token, setToken} = useState(null);

    // const visible = useSignup();

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <ExtendedPageBase authUser={authUser} />
            )}
        </AuthUserContext.Consumer>

    );
}


const condition = authUser => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withQuiz,
)(Likes);





