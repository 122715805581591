export const LANDING = '/';
// export const SIGN_UP = '/signup';
// export const SIGN_IN = '/signin';
// export const HOME = '/home';
export const PROFILE = '/profile';
// export const ADMIN = '/admin';
// export const PASSWORD_FORGET = '/pw-forget';
export const QUIZ = '/quiz';
// export const LOGINS = '/logins';
export const EXTEND = '/extend';
export const RECOMMEND = '/recommend';
export const DESCRIPTION = '/description';
export const LIKES = '/likes';
export const SETTINGS = '/settings';