import React, { Component } from 'react';

import {
    Form, Input, Icon, Button, Select, Row, Card, message,
} from 'antd';

import { purposes, itemtypes, improvements } from '../../constants/autocomplete';
import { logQuiz } from '../Functions';
import * as ROUTES from '../../constants/routes';

const { Option } = Select;
const { Meta } = Card;


// const itemTypeChildren = itemtypes.map(i => <Option key={i}>{i.charAt(0).toUpperCase() + i.slice(1)}</Option>);
const purposeChildren = purposes.map(i => <Option key={i}>{i}</Option>);
const improvementsChildren = improvements.map(i => <Option key={i}>{i}</Option>);


// function handleMultiSelectChange(value) {
//     console.log(`selected ${value}`);
// }



function onCheckboxChange(checkedValues) {
    console.log('checked = ', checkedValues);
}



let id = 0;

const selectAfter = (
    <Select defaultValue=".com" style={{ width: 80 }}>
        <Option value=".com">.com</Option>
        <Option value=".net">.net</Option>
        <Option value=".org">.org</Option>
        <Option value="other">other</Option>
    </Select>
);

class DynamicFieldSet extends Component {

    state = {
        radioValue: null,
        loading: false,
        error: null,
    };


    remove = (k) => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        // We need at least one website
        if (keys.length === 1) {
            return;
        }

        // can use data-binding to set
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    }

    add = () => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id++);
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
            keys: nextKeys,
        });
    }

    onNext = async (e) => {

        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                logQuiz(values, this.props.uid)
                    .catch(error => {
                        // this.setState({error});
                        console.log('update error', error);
                        // message.error(error.message, 2);
                    });;
                console.log('Received values of form: ', values);
            } else {
                message.error('Please check your entries for error', 1);
                console.log("here's the validation error:", err);
            }
        });

        // this.props.onSubmit();
        this.props.history.push(ROUTES.RECOMMEND);
    }


    onRadioChange = (e) => {
        console.log('radio2 checked', e.target.value);
        this.setState({
            radioValue: e.target.value,
        });
    }



    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };
        getFieldDecorator('keys', { initialValue: [] });
        const keys = getFieldValue('keys');
        const formItems = keys.map((k, index) => (
            <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? 'Websites' : ''}
                required={false}
                key={k}
            >
                {getFieldDecorator(`sitenames[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [{
                        required: true,
                        whitespace: true,
                        message: "Please input a website or delete this input box. At least one site is needed.",
                    }],
                })(
                    // <Input defaultValue="mysite"/>
                    <Input addonBefore="https://" allowClear placeholder="website name" addonAfter={selectAfter} style={{ width: '60%', marginRight: 8 }} />
                )}
                {keys.length > 1 ? (
                    <Icon
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        disabled={keys.length === 1}
                        onClick={() => this.remove(k)}
                    />
                ) : null}
            </Form.Item>
        ));
        return (


            <Form onSubmit={this.onNext} style={{ marginTop: 40 }}>

                <Form.Item
                >
                    <span className="ant-form-text">When you shop online for clothing or fashion, which websites do you visit?</span>
                </Form.Item>
                {formItems}
                <Form.Item>
                    <Button type="dashed" onClick={this.add} style={{ width: '100%' }}>
                        <Icon type="plus" /> Add website
            </Button>
                </Form.Item>


                <Form.Item
                >
                    <span className="ant-form-text">Thinking back to your past online clothing/fashion shopping experience, what could be better?</span>

                    <Row type="flex" justify="center">

                        {getFieldDecorator('improves', {
                            validateTrigger: ['onBlur'],
                            rules: [
                            ],
                        })(
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                placeholder="Select 1-3 options"
                            // defaultValue={['a10', 'c12']}
                            // onChange={handleMultiSelectChange}
                            >
                                {improvementsChildren}
                            </Select>
                        )}

                    </Row>
                </Form.Item>



                <Form.Item {...formItemLayoutWithOutLabel}>
                    <Button type="primary" htmlType="submit" style={{ width: "60%", float: "right", marginTop: 40 }}>Done</Button>
                </Form.Item>
            </Form>



        );
    }
}

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(DynamicFieldSet);

export default WrappedDynamicFieldSet;