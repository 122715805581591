import React from "react";
// import emotion
// import { css } from 'emotion';
 
// import icons
// import FaTwitter from 'react-icons/lib/fa/twitter';
// import FaFacebook from 'react-icons/lib/fa/facebook';
// import FaEnvelope from 'react-icons/lib/fa/envelope';
import { FaFacebook, FaEnvelope, FaTwitter, FaLinkedin, FaPinterest} from 'react-icons/fa'
// import FaPinterest from 'react-icons/lib/fa/pinterest';
// import FaLinkedin from 'react-icons/lib/fa/linkedin';
 
// import react-custom-share components
import { ShareButtonCircle, ShareBlockStandard } from 'react-custom-share';



const Social = props => {
    // create object with props for shareBlock
    const shareBlockProps = {
      url: 'https://chiccue.com/?refer=' + props.referer,
      button: ShareButtonCircle,
      buttons: [
        { network: 'Twitter', icon: FaTwitter },
        { network: 'Facebook', icon: FaFacebook },
        { network: 'Email', icon: FaEnvelope },
        { network: 'Pinterest', icon: FaPinterest, media: 'https://chiccue.com/image-to-share.jpg' },
        { network: 'Linkedin', icon: FaLinkedin },
      ],
      text: `Give it a try - chiccue.com`,
      longtext: `Take a look at this amazing website I have just found. Makes searching for styles A LOT easier.`,
    };
   
    return <ShareBlockStandard {...shareBlockProps} />;
  };

  export default Social;