import React from 'react';
import {
  Button
} from 'antd';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            needsEmailVerification(authUser) ? (
              <div style={{ paddingLeft: 24, paddingRight: 24, margin: "auto", maxWidth: 600 }}>
                <h1>Verify Your Email Address</h1>
                <p>
                  Check your email folder {authUser? <span>at <b>{authUser.email}</b> </span>:<span></span>}(spam
                  folder included) for a confirmation email.
                  If you can't find it, click the button to resend.
                    </p><p>
                  Refresh this page once you've confirmed your email.
                  </p>
                <Button
                  type="primary"
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}
                >
                  Resend confirmation email
                </Button>
              </div>
            ) : (
                <Component {...this.props} />
              )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
