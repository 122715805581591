import React from 'react';
import {
    Button, Spin
} from 'antd';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { makeCancelable } from '../Designs';



const withQuizBase = Component => {
    class WithQuiz extends React.Component {
        render() {
            return (<AuthUserContext.Consumer>
                {authUser => <Component {...this.props} authUser={authUser} />}
            </AuthUserContext.Consumer>);
        }
    }

    return withFirebase(WithQuiz);
}




const withQuiz = Component => {

class WithQuiz extends React.Component {

    constructor(props) {
        super(props);
// adding the loading state and the Spin effectively prevents the page to go back from loading review
// components to loading the redirect button once the quiz status is fetched
// preventing memory leak
        this.state = {
            needquiz: null,
            loading: true
        };

    }

    // pendingPromises = [];

    // componentWillUnmount = () =>
    //     this.pendingPromises.map(p => p.cancel());

    // appendPendingPromise = promise =>
    //     this.pendingPromises = [...this.pendingPromises, promise];

    // removePendingPromise = promise =>
    //     this.pendingPromises = this.pendingPromises.filter(p => p !== promise);


    // componentDidMount = () => {

    //     console.log(this.props);
    //     const authUser = this.props.authUser;
    //     const uid = this.props.authUser.uid;
    //     const query = this.props.firebase.db.ref(`users/${uid}/quiz`);
    //     const wrappedPromise = makeCancelable(query.once("value"));
    //     this.appendPendingPromise(wrappedPromise);

    //     wrappedPromise.promise
    //         .then(snapshot => snapshot.val())
    //         .then(q => {
    //             this.setState({
    //                 needquiz: authUser &&
    //                     authUser.emailVerified &&
    //                     authUser.providerData
    //                         .map(provider => provider.providerId)
    //                         .includes('password')
    //                     && q !== true
    //             })
    //             this.removePendingPromise(wrappedPromise);
    //         })
    //         .catch(errorInfo => {
    //             if (!errorInfo.isCanceled) {
    //                 console.log(errorInfo);
    //             }
    //             this.removePendingPromise(wrappedPromise);
    //         });

    // }


    componentDidMount = () => {

        const authUser = this.props.authUser;
        const uid = this.props.authUser.uid;
        const query = this.props.firebase.db.ref(`users/${uid}/quiz`);

        query.once("value")
            .then(snapshot => snapshot.val())
            .then(q => {
                this.setState({
                    needquiz: authUser &&
                        authUser.emailVerified &&
                        authUser.providerData
                            .map(provider => provider.providerId)
                            .includes('password')
                        && q !== true,
                    loading: false
                })

            })
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    console.log(errorInfo);
                }
            });

    }


    render() {

        return (
            this.state.loading? <div style={{ paddingLeft: 24, paddingRight: 24, margin: "auto", maxWidth: 100, marginTop: 60 }}><Spin/></div> :
            this.state.needquiz ? (

                <div style={{ paddingLeft: 24, paddingRight: 24, margin: "auto", maxWidth: 600 }}>
                    {/* <div>{this.needsQuiz(authUser)}</div> */}
                    <h1>Take the Style Quiz First</h1>
                    <p>
                        With your answers to the Style Quiz, we can find better styles for you.
              The Style Quiz is brief and should only take a few minutes. </p>
                    <p>
                        Click the button to go to the style quiz.
              </p>
                    <Button
                        type="primary"
                        onClick={() => this.props.history.push(ROUTES.QUIZ)}
                    >
                        Go
            </Button>
                </div>
            ) : (
                    <Component {...this.props} />
                )
                );}
           
}

return withQuizBase(WithQuiz);
}



export default withQuiz;
