import React, { Component } from 'react';
import {
  Form, Input, Tooltip, Icon, Button, Modal, message,
} from 'antd';

import { withFirebase } from '../Firebase';
import styles from '../../index.css';

const INITIAL_STATE = {
  error: null,
  confirmDirty: false,
  loading: false,
};


class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }



  handleCancel = () => {
    this.setState({ alert: false });
    this.props.form.resetFields();
    console.log("reset fields");
    this.props.handleCancel();
  };


  onSubmit = async (e) => {
    const form = this.props.form;
    const password = form.getFieldValue('password');


    e.preventDefault();

    await this.setState({ loading: true });

    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);

        this.props.firebase
          .doPasswordUpdate(password)
          .then(() => this.props.handleCancel())
          .then(() => {
            message.success("The password has been edited");
            this.props.onSubmit();
            this.setState({ ...INITIAL_STATE });
          })
          .catch(error => {
            this.setState({ error, loading:false });
            console.log('update error', error);
            message.error(error.message, 2);
          });

      } else {
        this.setState({ loading: false });
        message.error('Please check your entries for error', 1);
        console.log("here's the validation error:", err);
      }
    });


  }



  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('The passwords don\'t match');
      // this.setState({submitDisabled: true});
    } else {
      // this.setState({submitDisabled: false});
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }





  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      // <form onSubmit={this.onSubmit}>
      //   <input
      //     name="passwordOne"
      //     value={passwordOne}
      //     onChange={this.onChange}
      //     type="password"
      //     placeholder="New Password"
      //   />
      //   <input
      //     name="passwordTwo"
      //     value={passwordTwo}
      //     onChange={this.onChange}
      //     type="password"
      //     placeholder="Confirm New Password"
      //   />
      //   <button disabled={isInvalid} type="submit">
      //     Reset My Password
      //   </button>

      //   {error && <p>{error.message}</p>}
      // </form>



      <Modal
        visible={this.props.visible}
        title="Edit My Password"
        onCancel={this.handleCancel}  //remove this so that pressing esc doesn't bring in the close of the modal
        footer={null}
        closable={true}
        maskClosable={false}
      >

        <Form onSubmit={this.onSubmit} className={styles["login-form"]}>
          
          <Form.Item
            // {...formItemLayout}
            label={(
              <span>
                New Password&nbsp;
                <Tooltip title="Use letters, numbers and special characters">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            )}
          >
            {getFieldDecorator('password', {
              validateTrigger: ['onBlur'],
              rules: [{
                required: true, message: 'Password must be at least 6 characters', min: 6,
              }, {
                validator: this.validateToNextPassword,
              }],
            })(
              <Input.Password name="passwordOne" type="password" onChange={this.onChange} />
            )}
          </Form.Item>
          <Form.Item
            // {...formItemLayout}
            label="Confirm New Password"
          >
            {getFieldDecorator('confirm', {
              validateTrigger: ['onBlur'],
              rules: [{
                required: true, message: 'Password must be at least 6 characters',
              }, {
                validator: this.compareToFirstPassword,
              }],
            })(
              <Input.Password name="passwordTwo" type="password" onBlur={this.handleConfirmBlur} onChange={this.onChange} />
            )}
          </Form.Item>


          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
              Reset My Password
            </Button>

          </Form.Item>

        </Form>
      </Modal>


    );
  }
}
const WrappedPwChangeForm = Form.create({ name: 'pwchange' })(PasswordChangeForm);

export default withFirebase(WrappedPwChangeForm);