import React, { Component } from 'react';
import {
  Form, Icon, Input, Button, AutoComplete, Tooltip, Select, Card, Avatar, Col, message, Row
} from 'antd';



// import styles from '../../index.css';

import { countries, usprovinces, caprovinces } from '../../constants/autocomplete';
import { makeCancelable } from '../Designs';

const { Option } = Select;
const { Meta } = Card;


let provinces = [];

const us = countries.find(function (element) {
  return element.toLowerCase().indexOf('united states') !== -1;
});



const INITIAL_STATE = {
  error: null,
  loading: true,
  country: '',
  province: '',
  city: '',
  gender: '',
  yob: '',
  termObject: {},
};

class UserFormBase extends Component {

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };

  }

  pendingPromises = [];

  componentWillUnmount = () =>
    this.pendingPromises.map(p => p.cancel());

  appendPendingPromise = promise =>
    this.pendingPromises = [...this.pendingPromises, promise];

  removePendingPromise = promise =>
    this.pendingPromises = this.pendingPromises.filter(p => p !== promise);


  componentDidMount = () => {

    const ref = this.props.firebase[this.props.refname];
    const query = ref(this.props.uid);

    const wrappedPromise = makeCancelable(query.once("value"));
    this.appendPendingPromise(wrappedPromise);

    wrappedPromise.promise
      .then(snapshot => snapshot.val())
      .then(termObject => {
        if (termObject) {
          this.setState({
            country: termObject.country,
            city: termObject.city,
            gender: termObject.gender,
            province: termObject.province,
            yob: termObject.yob,
            loading: false 
          });
        }
        this.removePendingPromise(wrappedPromise);
      })
      .catch(errorInfo => {
        if (!errorInfo.isCanceled) {
          this.setState({ error: errorInfo.error, loading: false });
          this.removePendingPromise(wrappedPromise);
        }
      });

  }


  onNext = async (e) => {

    const { uid, refname } = this.props;
    const { country, province, city, gender, yob } = this.state;
    const ref = this.props.firebase[refname];

    e.preventDefault();


    this.props.form.validateFields((err, values) => {

      if (!err) {

        console.log('Received values of form: ', values);
        ref(uid)
          .update({
            country, province, city, gender, yob
          })
          .then(() => {
            // this.setState({ loading: false });
            this.props.onSubmit();
          })
          .catch(error => {
            // this.setState({ error});
            console.log('update error', error);
            message.error(error.message, 2);
          });

      } else {
        message.error('Please check your entries for error', 1);
        console.log("here's the validation error:", err);
      }
    });


  }


  handleConfirmYob = (rule, value, callback) => {
    // const { getFieldValue } = this.props.form;
    // console.log(typeof value);  //string!
    if (!value || value.match(/[^\d]/) || parseInt(value, 10) < 1900 || parseInt(value, 10) > 2019) {
      callback('Invalid year');
    }
    callback()
  }

  handleConfirmCountry = (rule, value, callback) => {
    // const { getFieldValue } = this.props.form;
    // console.log(typeof value);  //string!
    if (value && !countries.includes(value)) {
      callback('Please select among provided country names');
    }
    callback()
  }



  onSelectChange = (key) => (value) => {
    if (value) {
      const newvalue = value.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
      this.setState({ [key]: newvalue });
    }
  };


  onChange = event => {
    const newvalue = event.target.value.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
    this.setState({ [event.target.name]: newvalue });
  };




  onSelect = (key) => (value) => {

    console.log("selected name:", value);

    switch (value) {
      case us:
        console.log('United States is chosen as the country. Updating provinces');
        provinces = usprovinces;
        break;
      case 'Canada':
        console.log('Canada is chosen as the country. Updating provinces');
        provinces = caprovinces;
        break;
      default:
        provinces = [];
        console.log('Not updating provinces');
    }

    this.setState({ [key]: value });

  }



  render() {
    const { getFieldDecorator } = this.props.form;
    const { country, province, city, gender, yob } = this.state;

    //   getFieldDecorator gives a field its id. e.g. 'email', then id='normal_login_email'

    return (

      <Form style={{ marginTop: 20 }}>
        <Form.Item
          label={(
            <span>
              Country/Territory you live in&nbsp;
                  <Tooltip title="Stores have different shipping and return policies, depending on where you live">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          )}
          extra="input two or more letters to select your country"
        >
          {getFieldDecorator('country', {
            validateTrigger: ['onBlur'],
            rules: [{ required: true, message: ' ' },
            { validator: this.handleConfirmCountry }
            ],
            initialValue: country,
          })(

            <AutoComplete
              dataSource={countries}
              style={{ width: "100%" }}
              onSelect={(value) => this.onSelect("country")(value)}
              defaultOpen={false}
              allowClear
              // open={false}
              // autoFocus={true}
              // onSearch={this.handleSearch}
              // placeholder="type to select"
              filterOption={(inputValue, option) => inputValue.length >= 2 ? option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 : false}
            />
          )}
        </Form.Item>

        <Form.Item
          label={(
            <span>
              State/Province/Area
                </span>
          )}
        //  extra="input a few letters to select your country"
        >
          {getFieldDecorator('province', {
            rules: [{ required: true, message: ' ' },
            { max: 30, message: 'Input exceeds maximum length' },
            ],
            initialValue: province,
          })(
            <AutoComplete
              name="province"
              dataSource={provinces}
              style={{ width: "100%" }}
              allowClear
              onSelect={(value) => this.onSelect("province")(value)}
              onChange={(value) => this.onSelectChange("province")(value)}
              // onSearch={this.handleSearch}
              // placeholder="type to select"
              filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            />
          )}
        </Form.Item>


        <Form.Item
          label={(
            <span>
              City/Town
                </span>
          )}
        //  extra="input a few letters to select your country"
        >
          {getFieldDecorator('city', {
            rules: [{ required: true, message: ' ' },
            { max: 30, message: 'Input exceeds maximum length' },],
            initialValue: city,
          })(
            <Input
              name="city"
              style={{ width: "100%" }}
              allowClear
              onChange={this.onChange}
            />
          )}
        </Form.Item>

        <Form.Item
          label={(
            <span>
              Gender
                </span>
          )}
        //  extra="input a few letters to select your country"
        >
          {getFieldDecorator('gender', {
            rules: [{ required: true, message: 'Please input your gender' }],
            initialValue: gender,
          })(
            <Select
              // placeholder="Please select"
              style={{ width: "100%" }}
              onSelect={(value) => this.onSelect("gender")(value)}
            >
              <Option value="female">Female</Option>
              <Option value="male">Male</Option>
            </Select>
          )}
        </Form.Item>


        <Form.Item
          label={(
            <span>
              Year of Birth&nbsp;
                  <Tooltip title="This matters less often than you think... ">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          )}
        >
          {getFieldDecorator('yob', {
            // getValueFromEvent: (e) => {
            //   const convertedValue = Number(e.target.value);
            //   // console.log(convertedValue);
            //   if (isNaN(convertedValue) || convertedValue === 0) {
            //     console.log("not a number");
            //     // return 0;   //Number(this.props.form.getFieldValue("testNumber"));
            //   } else {
            //     return convertedValue;
            //   }
            // },
            validateTrigger: ['onBlur'],
            rules: [
              { required: true, message: ' ' },
              // {type: "integer"},
              // { required: true, type: "integer", min: 1900, max: 2019, message: 'Please input a valid year of birth' },
              { validator: this.handleConfirmYob },
            ],
            initialValue: yob,
          })(
            <Input name="yob" style={{ width: "100%" }} onChange={this.onChange} />
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={this.onNext} style={{ width: "60%", float: "right", marginTop: 40 }}>Next</Button>

        </Form.Item>
      </Form>

    );
  }
};

const WrappedUserFormBase = Form.create({ name: 'user_question' })(UserFormBase);


export default WrappedUserFormBase;