import React, { Component } from 'react';

import {
    Form, Icon, Button, Tooltip, Select, Row, Tag, Card, Modal, message, Skeleton
} from 'antd';

import { attris, colormapping } from '../../constants/autocomplete';
import { makeCancelable } from '../Designs';

const Option = Select.Option;
const { Meta } = Card;

const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
        obj[item[keyField]] = item
        return obj
    }, {})

const save2list = arrayToObject(attris, "savelabel");
const display2list = arrayToObject(attris, "displaylabel");

const mintagsrequired = 3;


const selectChildren = attris.map(i => <Option key={i['savelabel']}>{i['displaylabel']}</Option>);

const maxlabellength = 25;


function handleChange(value) {
    console.log(`selected ${value}`);
}

const AttributesModal = (props) =>
    <Modal
        key={props.modalTitle}
        title={<span>{props.modalTitle}&nbsp;<Tooltip title="Add specific styles. For example is there any kind of cut you don't like? Patterns? Materials?"><Icon type="question-circle-o" /></Tooltip></span>}
        visible={props.visible}
        onOk={props.onClick}
        maskClosable={false}
        confirmLoading={props.confirmLoading}
        onCancel={props.handleCancel}
        bodyStyle={{ height: 200 }}
        okText={"Add"}
    >
        <p>Input words or phrases. Add as many as you like. Don't be limited by the choices here:</p>
        <Select
            value={props.searchTerm}
            mode="tags"
            style={{ width: '95%' }}
            placeholder="Try, for example, &quot;too loose&quot;, &quot;low-cut neck&quot;, &quot;yellow&quot;, &quot;animal print&quot;"
            onChange={handleChange}
            hideAction={"click"}
            onSelect={props.onSelect}
            onDeselect={props.onDeselect}
            // filterOption={(inputValue, option) => inputValue.length >= 2 ? display2list[option
            // .props.children.toLowerCase()]['searchlabel'].includes(inputValue
            // .toLowerCase()) : false}  this unfortunately doesn't cover the case of searching "too long" in "too super long"
            // so it has to be searching for each string split by space. Only when all matches would the result be returned
            filterOption={(inputValue, option) => {
                const displaylbl = option.props.children.toLowerCase();
                const inputs = inputValue.toLowerCase().split(' ');
                const lbl = (display2list[displaylbl] && display2list[displaylbl].searchlabel) || displaylbl;
                return inputValue.length >= 2 ? inputs.every(subs => lbl.indexOf(subs) >= 0) : false
            }}
        // dropdownStyle={{maxHeight:100}}
        >
            {selectChildren}
        </Select>

    </Modal>


class AttributePrefQBase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchTerm: [],
            error: null,
            iniloading: true,
            loading: true,
            termObject: {},
            visible: false,
            confirmLoading: false,
            modalTitle: '',
        };


    }

    pendingPromises = [];

    componentWillUnmount = () =>
        this.pendingPromises.map(p => p.cancel());

    appendPendingPromise = promise =>
        this.pendingPromises = [...this.pendingPromises, promise];

    removePendingPromise = promise =>
        this.pendingPromises = this.pendingPromises.filter(p => p !== promise);




    onNext = async (e) => {

        // const { email, password } = this.state;

        e.preventDefault();

        if (this.state.termObject && Object.keys(this.state.termObject).length >= mintagsrequired) {
            this.props.onSubmit();
        } else {
            message.error(`Need at least ${mintagsrequired} style tags`, 2);
        }
    }


    componentDidMount = () => {

        const ref = this.props.firebase[this.props.refname];
        const query = ref(this.props.uid);

        const wrappedPromise = makeCancelable(query.once("value"));
        this.appendPendingPromise(wrappedPromise);

        wrappedPromise.promise
            .then(snapshot => snapshot.val())
            .then(termObject => {
                this.setState({ termObject, loading: false });
                this.removePendingPromise(wrappedPromise);
            })
            .catch(errorInfo => {
                if (!errorInfo.isCanceled) {
                    this.setState({ error: errorInfo.error, loading: false });
                    this.removePendingPromise(wrappedPromise);
                }
            });

    }


    onDismissTerm = (savelabel) => {
        const { uid, refname } = this.props;
        const { termObject } = this.state;

        let newto = { ...termObject };  //clone object
        delete newto[savelabel];

        this.setState({ termObject: newto });
        this.removeFromDb(uid, savelabel, refname);
    }



    onSelect = (savelabel) => {
        // we should operate with savelabel, not displaylabel

        if (savelabel.length > maxlabellength) {
            message.error('Description too long', 1);
        } else {
            this.setState((state, props) => ({
                searchTerm: [...state.searchTerm, savelabel]
            }));
        }
    }

    onDeselect = (savelabel) => {
        // we should operate with savelabel, not displaylabel

        this.setState({
            searchTerm: this.state.searchTerm.filter(t => t !== savelabel),
            // termHistory,
        });
    }




    removeFromDb(uid, label, refname) {
        const ref = this.props.firebase[refname];
        return ref(uid).child(label)
            .remove()
            .catch(error => {
                this.setState({ error })
            });
    }


    onClick = () => {

        const { searchTerm, termObject } = this.state;
        const { uid, refname } = this.props;
        const ref = this.props.firebase[refname];
        let termsObj;
        const cleanterms = [...new Set(searchTerm.map(i => i.trim()))];

        if (searchTerm.length < 1) {
            return;
        }

        // console.log('termHistory:', termHistory);
        termsObj = Object.assign(...cleanterms.map(i => ({ [i]: ((save2list[i] && save2list[i].aspect) || 'other') })));

        this.setState({
            searchTerm: [],
            termObject: { ...termObject, ...termsObj },
            visible: false,
            confirmLoading: false,
        });

        ref(uid)
            .update(termsObj)
            .catch(error => {
                this.setState({ error });
                console.log(error);
            });

    }


    showModal = (title) => {
        this.setState({
            modalTitle: title,
            visible: true,
        });
    }

    handleCancel = () => {
        console.log('Clicked cancel button');
        this.setState({
            visible: false,
        });
    }


    render() {
        const {
            loading,
            searchTerm,
            termObject,
            visible,
            confirmLoading,
            modalTitle
        } = this.state;

        const termTags = termObject && Object.entries(termObject).map(i => <Tag key={i[0]} style={{ marginTop: 6 }}
            closable afterClose={() => this.onDismissTerm(i[0])} color={colormapping[i[1]]}>
            <Tooltip title={i[1]}>
                {(save2list[i[0]]
                    && save2list[i[0]].displaylabel) || i[0]}
            </Tooltip></Tag>);


        return (
            <Card bordered={false}
                style={{ marginTop: 16, width: "100%" }}
            >
                <Skeleton loading={loading} active>
                    <Meta
                        title={
                            <span>
                                Styles to <b>avoid</b>
                            </span>
                        }
                        description={<span>
                            <Tooltip title="Colors of your added tags represent aspects of style. Hover on a style tag to see details. Press &quot;x&quot; to remove. ">
                                What sort of styles would you avoid? Add {mintagsrequired} or more &nbsp;<Icon type="question-circle-o" />
                            </Tooltip></span>}
                    />

                    <div style={{ marginTop: 40, minHeight: 300 }}>
                        {termTags}
                    </div>

                    <div style={{ marginTop: 40, minHeight: 50 }}>
                        <Button type="normal" style={{ marginTop: 40, width: "100%" }} onClick={() => this.showModal("Styles you avoid")}>
                            <Icon type="plus" />Add Styles
                    </Button>
                    </div>

                    <div style={{ marginTop: 40, minHeight: 50 }}>
                        <Button type="primary" htmlType="submit" onClick={this.onNext} style={{ marginTop: 40, width: "60%", float: "right" }}
                        >Next</Button>
                    </div>

                    <AttributesModal visible={visible} searchTerm={searchTerm} modalTitle={modalTitle}
                        confirmLoading={confirmLoading} onClick={() => this.onClick(modalTitle)} handleCancel={this.handleCancel}
                        onSelect={this.onSelect} onDeselect={this.onDeselect} />
                </Skeleton>
            </Card>

        );
    }
}


const WrappedAttributesFormBase = Form.create({ name: 'user_question' })(AttributePrefQBase);


export default WrappedAttributesFormBase;

