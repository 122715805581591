// import React from 'react';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Collapse, Row, Col } from 'antd';
import SignUpForm from '../SignUp';
import {useModal} from '../Hooks';

const Panel = Collapse.Panel;



const Howtext = (props) => 
  <p style={{ paddingLeft: 24 }}> Start with us by<Link to="#" onClick={props.handleSignup}>&nbsp;anwswering a few questions&nbsp;</Link> 
  about your style preferences so that we can tailor our recommendations to your liking. </p>



const introtext = (search) =>
  <div>
    <p style={{ paddingLeft: 24 }}>Chiccue makes your fashion exploration more pleasurable. </p>
    <p style={{ paddingLeft: 24 }}>Chiccue notes fashion items you like and dislike,
      and gently cue you with fresh items you are more likely to enjoy. Gentle,
      because we understand aethesthetics is subtle, and instead of replacing your aesthetic sense and displacing your uniqueness, we only aim to bounce ideas.
      In addition, unlike many other websites, we don't waste your time by repeatedly pestering
  you with items you don't like or have passed on, and we don't push you to spend. </p>
    {/* <p> Start with us by <Link to={ROUTES.SIGN_UP + search}>answering a few questions</Link> about your style preferences so that we can tailor our recommendations to your liking. </p> */}
    
  </div>

var sectionStyle = {
  background: "url('https://upload.wikimedia.org/wikipedia/commons/c/c9/Pierre-Auguste_Renoir_022.jpg') no-repeat center center",
  WebkitBackgroundSize: "contain",
  MozBackgroundSize: "contain",
  OBackgroundSize: "contain",
  backgroundSize: "contain",
  maxWidth: "2000px",
  height: "800px",
  paddingLeft: 24,
  paddingRight: 24,
  backgroundPosition: "top",
  // backgroundImage: `url('https://upload.wikimedia.org/wikipedia/commons/c/c9/Pierre-Auguste_Renoir_022.jpg')`,
  // backgroundSize: "100%",
  // backgroundRepeat:"no-repeat",
};

function Landing(props) {

  const {visible, cancelModal, showModal} = useModal();

  // const visible = useSignup();

  const h1style= {
    WebkitTextStroke: "1px white",
    WebkitTextFillColor: "white",
    color: "white",
    // textShadow:
    //     "3px 3px 0 #000, -1px -1px 0 #000,  1px -1px 0 #000, -1px 1px 0 #000, 2px 2px 0 #000"

        // textShadow:
        // "2px 2px 0 #000, 2px 2px 0 #000,  2px 2px 0 #000, 2px 2px 0 #000, 2px 2px 0 #000"
  }

  return (
    <React.Fragment>
  <div style={sectionStyle}>
 
  <Row type="flex" justify="center">
    <h1 style={h1style}>Welcome to Chiccue</h1>
    </Row>
    <Row type="flex" justify="center">
    <h2 style={h1style}>Smart Dressing For Smart People</h2>
    </Row>
    <Collapse bordered={false} style={{opacity: 0.7}}>
      <Panel header="What is Chiccue?" key="1">
        {introtext(props.location.search || '')}
      </Panel>
      <Panel header="How does this work?" key="2">
        <Howtext handleSignup={showModal}/>
      </Panel>
    </Collapse>
    <SignUpForm visible={visible} handleCancel={cancelModal}/>
    </div>
    <div style={{marginTop: 10}}>
    <Row type="flex" gutter={2}>
                    <Col span={4} offset={2}>
                        <NavLink
                            to="/about"
                            activeClassName="selected">About
                        </NavLink>
                    </Col>
                    <Col span={4} offset={2}>
                        <NavLink
                            to="/terms"
                            activeClassName="selected">Terms of Service
                        </NavLink>
                    </Col>
                    <Col span={4} offset={2}>
                        <NavLink
                            to="/privacy"
                            activeClassName="selected">Privacy Policy
                        </NavLink>
                    </Col>
                    <Col span={4} offset={2}>
                        <NavLink
                            to="/Help"
                            activeClassName="selected">Help
                        </NavLink>
                    </Col>
                </Row>
                </div>
  </React.Fragment>
  );
}


export default Landing;

// doesn't matter if the import name is different: automatic alias
